import React, { useEffect, useState } from "react";
import CustomNavbar from "../components/navbar/Navbar";
import Footeralt from "../components/footer2/Footeralt";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import TopCard from "../components/topCard/TopCard";
import butuncultip from "../components/documents/butuncultipinilkeleri.pdf";
import cinsiyetvestres from "../components/documents/cinsiyetvestreshormanlari.pdf";
import MyPDFViewer from "../components/pdfViewer";

function FonksiyonelTıp() {
  const [showViewer, setShowViewer] = useState(false);
  const [showViewer2, setShowViewer2] = useState(false);
  const [topCardHeight, setTopCardHeight] = useState(0);
  const openModal = () => {
    setShowViewer(true);
    document.body.style.overflow = "hidden";
  };
  const closeModal = () => {
    setShowViewer(false);
    document.body.style.overflow = "auto";
  };
  const openModal2 = () => {
    setShowViewer2(true);
    document.body.style.overflow = "hidden";
  };
  const closeModal2 = () => {
    setShowViewer2(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const topCardElement = document.querySelector(".top-card");
    if (topCardElement) {
      const topCardRect = topCardElement.getBoundingClientRect();
      setTopCardHeight(topCardRect.height);
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position = "relative";
        navbarElement.style.backgroundColor = "grey";
      }
    }

    function handleScroll() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position =
          scrollY > topCardHeight ? "fixed" : "relative";
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topCardHeight]);

  return (
    <div>
      <TopCard className="top-card" />
      <div className="navbar-fixed">
        <CustomNavbar />
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container>
          <Row>
            <Col md={12}>
              <Card className="mb-4 mt-3">
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    FONKSİYONEL & BÜTÜNCÜL TIP YAKLAŞIMI
                  </Card.Title>
                  <Card.Text>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Fonksiyonel Tıp Neler Sunabilir?
                          </Card.Title>
                          <Card.Text>
                            Fonksiyonel tıp, kişiselleştirilmiş bir tedavi
                            sunan, bilime ve kanıta dayalı, bütünsel bir sağlık
                            yaklaşımıdır. Semptomları yönetmenin yanısıra sağlık
                            sorunlarınızın altında yatan temel nedenleri ele
                            alır ve kökten çözüm sunmayı hedefler. Aynı zamanda
                            herhangi bir sağlık sorunu olmadan mevcut sağlığı
                            korumak, sağlığı teşvik etmek ve hastalıkları
                            önlemek fonksiyonel tıp yaklaşımının ilgi alanları
                            dahilindedir. Fonksiyonel tıp hekimi, bu anlamda eş
                            zamanlı sağlık koçunuz olmaktadır. Prensip olarak
                            çeşitli sağlık sorunlarıyla başvuran her kişiye
                            fonksiyonel tıp bakış açısı ile her zaman
                            bireyselleştirilmiş bir yaklaşım sunulması
                            hedeflenmektedir.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Fonksiyonel Tıp Aynı Zamanda Bütüncül Müdür?
                          </Card.Title>
                          <Card.Text>
                            Bütüncül Tıp Nedir? <br />
                            Hekimler olarak hastayı sadece belirli alanlarda
                            incelemek bizim büyük resmi kaçırmamıza sebep
                            olabilir.Geleneksel tıp akut sorunları tedavi etmek
                            için gerekli ve başarılı olsa da kronik hastalıkları
                            ve teşhis edilmemiş sorunları tedavi etmek ya da
                            önlemek konusunda sorun yaşayabiliyor.Bazı kişiler
                            için başarılı olan tedaviler bir başkası için işe
                            yaramayabilir.Bütüncül tıp yaklaşımının devreye
                            girdiği yer burasıdır.Geleneksel olmayan tıbbi
                            terapileri kullanan bütüncül tıp, her iki bakış
                            açısının da en iyisi olan son teknoloji geleneksel
                            tıbbi tedavileri de dahil edebilir.Bütüncül tıp,
                            zihinsel, bedensel , ruhsal ve sosyal alanlar dahil
                            olmak üzere sizi bütün olarak dikkate alan bir
                            yaklaşımdır.Günümüz dünyasında Batı tıbbı olarak da
                            bilinen geleneksel tıp hekimleri esas olarak vücudun
                            belli bölgelerine odaklanmıştır.Bu yaklaşım,
                            hastalığın belirti ve bulgularını ilaç ve/ veya
                            ameliyat yoluyla tedavi eder.Bütüncül tıp, büyük
                            resme odaklanır ve geleneksel yaklaşımı destekleyen
                            bir bakış açısı içerir.Bütüncül tıbbın bu geniş
                            yaklaşımı, sadece hastalığın belirti ve
                            semptomlarını değil, kişiyi bir bütün olarak tedavi
                            etmeyi amaçlamaktadır.Bütüncül tıp, dünyadaki kronik
                            hastalık salgınının ele alınması için başarılı bir
                            yaklaşım olarak kabul edilmektedir.
                            <br />
                            Bütüncül tıp, ihtiyaçlarınıza ve isteklerinize en
                            uygun bireyselleştirilmiş tedavi planlarını
                            kullanır.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Text
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            Bütünleştirici Tıp ve Fonksiyonel Tıp, 21. yüzyılın
                            sağlık hizmetlerinin ön saflarında yer almaktadır.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>Fonksiyonel Tıp Yaklaşımımız</Card.Title>
                          <Card.Text>
                            Fonksiyonel tıp , hastalığın temel nedenlerini ele
                            almayı ve tanımlamayı amaçlar.Vücudu tıbbi
                            uzmanlıklar tarafından bölünmüş hepsi birbirinden
                            bağımsız bir organ topluluğu değil, tek bir entegre
                            sistem olarak görür.Fonksiyonel tıp sadece
                            semptomları değil, tüm sistemi tedavi eder.
                            Fonksiyonel tıp sağlıklı ve zinde olmayı sağlamayı
                            hedefler.
                          </Card.Text>
                          <Card.Text>
                            Fonksiyonel tıp yaklaşımı ile daha ‘hasta’ merkezli
                            davranarak geleneksel tıbbın alışılmış ‘hastalık‘
                            merkezli yaklaşımını tersine çeviriyoruz. İzole
                            olarak sadece semptomları ele almıyoruz, bunun
                            yerine hastalarımızı bir bütün olarak görmeye
                            odaklanıyoruz. Fonksiyonel tıp hekimimiz
                            hastalarımızla önemli miktarda zaman geçirir,
                            hikayelerini ‘dinler’ ve bireye ait uzun vadeli
                            karmaşık, kronik koşullara katkıda bulunan genetik,
                            çevresel ve yaşam tarzı faktörleri arasındaki
                            etkileşimlerini içeren bir zaman çizelgesi
                            geliştirir.Bu bağları ortaya çıkardıkça, sağlıklı
                            yaşama doğru nasıl ilerleyeceğinizi belirleyebilir.
                            Fonksiyonel tıbbın etkinliği, fonksiyonel tıp hekimi
                            ve hastaları arasındaki işbirlikçi ilişkiden
                            kaynaklanmaktadır.Bu ilişki, bilgi alışverişine,
                            hastalık veya hastalık anlayışına, kök nedenlerin ve
                            tedavilerin eğitimine ve hastaların kendilerini daha
                            iyi hissetmelerine olanak sağlar.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Her Zaman "Neden?" Diye Soruyoruz
                          </Card.Title>
                          <Card.Text>
                            "Neden?" diye soruyoruz çünkü yaşadığınız
                            semptomların ötesine bakmak istiyoruz ve bu
                            semptomları yönlendiren altta yatan kök sebeplere
                            ulaşmak istiyoruz.
                          </Card.Text>
                          <Card.Text
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                          >
                            Fonksiyonel Tıp’ta "herkese uyan tek beden
                            yaklaşımı" yoktur.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Row className="d-flex">
                    <Col md={4} className="d-flex">
                        <Card className="mb-4 flex-fill custom-card">
                          <Card.Body>
                            <Card.Title>Bireysel</Card.Title>
                            <Card.Text className="custom-card-text">
                              Tedaviniz sizin, ihtiyaçlarınız, yaşam tarzınız ve
                              hedefleriniz etrafında inşa edilir.Fonksiyonel tıp
                              hekimimiz , sizin için uygun tedavi sürecini
                              belirlemek üzere endişelerinize, geçmiş öykünüze
                              ve tetkik sonuçlarınıza bakacaktır.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      
                      <Col md={4} className="d-flex">
                        <Card className="mb-4 flex-fill custom-card">
                          <Card.Body>
                            <Card.Title>Kapsamlı</Card.Title>
                            <Card.Text className="custom-card-text">
                              Sağlık öykünüze değer veriyoruz ve yolculuğunuzda
                              önce sizinle tanışmak istiyoruz.Hikayenizi
                              dinliyoruz, hayatınız hakkında bilgi ediniyoruz ve
                              kronik hastalıkları ve uzun vadede sağlığı
                              etkileyebilecek genetik, çevresel ve yaşam tarzı
                              faktörleri arasındaki etkileşimlere bakıyoruz.
                              Geleneksel tıp çoğu zaman hastanın hikayesini
                              reddeder veya gerçekten dinlemek için nadiren
                              zaman ayırabilir. Hastaların birçok soruları
                              yanıtsız kalabilir. Hastanın geçmişini mümkün
                              olduğunca kavrayıp anlayarak, hedefe ulaşmaya
                              yardımcı olmak için kişiselleştirilmiş bir sağlık
                              planı oluşturuyoruz.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={4} className="d-flex">
                        <Card className="mb-4 flex-fill custom-card">
                          <Card.Body>
                            <Card.Title>Bütüncül</Card.Title>
                            <Card.Text className="custom-card-text">
                              Fonksiyonel tıp ile daha sağlıklı bir sonuç
                              sağlamak için tedavi ve önlemeye yönelik bütünsel
                              ve doğal yaklaşımlar kullanılır. Fonksiyonel tıp,
                              geleneksel tıbbın kanıtlanmış en iyi alanlarını
                              etkili ve bütüncül yaklaşımlarla birleştirir.
                              Belirtileri bireysel sistemlerden izole etmeden
                              hastamızı bir bütün olarak görüyoruz.Geleneksel
                              tıp, sadece semptomlara odaklanıp tüm vücudu tek
                              bir entegre sistem olarak düşünmeye yeterli zaman
                              ayrılmadığı için genellikle kök nedeni kaçırır.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={12}>
                        <Card className="mb-4">
                          <Card.Body>
                            <Card.Text>
                              Amaç sadece hastalıksız olmak değil, aynı zamanda
                              sizin için geçerli olan sağlıklı bir yaşam tarzını
                              keşfetmektir.Sizi mevcut durumunuzdan gerçekten
                              gelişmeye iten değişiklikler yapmak için sizinle
                              birlikte çalışıyoruz. Hastalık ve
                              yakınmalarınızdan arınmış olarak hayatınızı
                              yaşamayı optimize etmenize yardımcı olmak
                              istiyoruz.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title
                            style={{ textAlign: "center", cursor: "pointer" }}
                            onClick={openModal}
                          >
                            BÜTÜNCÜL TIP'IN İLKLERİ İLE İLGİLİ DETAYLI BİLGİ
                            İÇİN TIKLAYINIZ
                          </Card.Title>
                        </Card.Body>
                      </Card>
                      <MyPDFViewer
                        pdfFile={butuncultip}
                        isOpen={showViewer}
                        onClose={closeModal}
                      />
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>Fonksiyonel Tıp</Card.Title>
                          <Card.Text>
                            Fonksiyonel tıp, semptomları bastırmak yerine bu
                            durumun nedenini belirlemek ve tedavi edebilmek için
                            çalışır. Fonksiyonel tıp yaklaşımı ile yan etkilere
                            sebep olarak ek sağlık sorunlarına katkıda
                            bulunabilecek ilaçlarla hızlı bir düzeltme yerine
                            kişinin tüm sağlığını iyileştirmeye odaklanıyoruz.
                            Sisteminizi düzenleyen standard bir sağlık hizmeti
                            modelinde çoğu zaman göz ardı edilen
                            dengesizlikleri, beslenme eksikliklerini ve
                            metabolik işlev bozukluklarını keşfederek vücuda bir
                            bütün olarak bakar. Gelişmiş teşhis yöntemleri,
                            beslenmeye yeniden odaklanma, takviyelerin stratejik
                            kullanımı ile daha sağlıklı olmayı teşvik etmek için
                            temel sorunları ele alabilir ve düzeltebiliriz.
                            Mevcut sağlık koşullarınız sizi en iyi halinizden
                            alıkoymamalıdır. Elbette bu sizin de işbirliğiniz
                            ile mümkündür.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Row className="d-flex">
                      <Col md={12} className="d-flex">
                        <Card className="mb-4 flex-fill">
                          <Card.Body>
                            <Card.Title>
                              Fonksiyonel Tıp Alanına Dahil Olan Hastalıklar
                            </Card.Title>
                            <Row>
                              <Col md={4}>
                                <ListGroup>
                                  <ListGroup.Item>
                                    Ağır Metal Toksisitesi (Cıva, Arsenik,..)
                                  </ListGroup.Item>
                                  <ListGroup.Item>Akne</ListGroup.Item>
                                  <ListGroup.Item>
                                    Akut Hastalıklar - enfeksiyonlar, sinüzit,
                                    ağrılar
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Alzheimer ve Demans
                                  </ListGroup.Item>
                                  <ListGroup.Item>Artrit</ListGroup.Item>
                                  <ListGroup.Item>Astım</ListGroup.Item>
                                  <ListGroup.Item>
                                    Anksiyete ve Depresyon
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Çevre ve gıda alerjileri
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Dikkat Eksikliği ve Hiperaktivite Bozukluğu
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Diyabet, Metabolik Sendrom, Prediyabet,
                                    İnsülin Direnci
                                  </ListGroup.Item>
                                </ListGroup>
                              </Col>
                              <Col md={4}>
                                <ListGroup>
                                  <ListGroup.Item>
                                    Egzama/Sedef Hastalığı
                                  </ListGroup.Item>
                                  <ListGroup.Item>Fibromiyalji</ListGroup.Item>
                                  <ListGroup.Item>İntoksikasyon</ListGroup.Item>
                                  <ListGroup.Item>
                                    Kadın Hastalıkları (Premens. Sendrom,
                                    Polikistik Over Sendromu..)
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Kalp Sağlığı, Yüksek Kolesterol ve
                                    Hipertansiyon
                                  </ListGroup.Item>
                                  <ListGroup.Item>Kanser Önleme</ListGroup.Item>
                                  <ListGroup.Item>
                                    Kronik Yorgunluk
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Kronik Sinüzit
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Menopoz ve perimenopoz
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Mental yorgunluk, Beyin Sisi
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Migren ve Başağrısı
                                  </ListGroup.Item>
                                </ListGroup>
                              </Col>
                              <Col md={4}>
                                <ListGroup>
                                  <ListGroup.Item>
                                    Multiple Skleroz (MS Hastalığı)
                                  </ListGroup.Item>
                                  <ListGroup.Item>Osteoporoz</ListGroup.Item>
                                  <ListGroup.Item>Otizm</ListGroup.Item>
                                  <ListGroup.Item>
                                    Otoimmün Hastalıklar
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Parazit ve Bağırsak Enfeksiyonları
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Parkinson Hastalığı
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Sağlıklı Yaş Alma, Kilo ve Metabolizma
                                    Dengesi
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Sindirim Bozuklukları (IBS, Reflü, Kolit,
                                    Gluten Duyarlılığı)
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    Testesteron Eksikliği
                                  </ListGroup.Item>
                                  <ListGroup.Item>Uyku Sorunu</ListGroup.Item>
                                </ListGroup>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={6} className="d-flex">
                        <Card className="mb-4 flex-fill">
                          <Card.Body>
                            <Card.Title>Metabolik Sorunlar</Card.Title>
                            <Card.Text>
                              Yaygın Metabolik Sorunlar:
                              <ul>
                                <li>Kilo kaybı, kilo alma</li>
                                <li>Diyabet, prediyabet</li>
                                <li>Metabolik Sendrom</li>
                                <li>Hipertansiyon</li>
                              </ul>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={6} className="d-flex">
                        <Card className="mb-4 flex-fill">
                          <Card.Body>
                            <Card.Title>Bağışıklık Sorunları</Card.Title>
                            <Card.Text>
                              Yaygın Bağışıklık Sorunları:
                              <ul>
                                <li>Çölyak Hastalığı</li>
                                <li>Hashimoto Tiroidit</li>
                                <li>İnflamatuar Bağırsak Hastalığı</li>
                                <li>Romatoid Artrit</li>
                                <li>Lupus</li>
                              </ul>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={6} className="d-flex">
                        <Card className="mb-4 flex-fill">
                          <Card.Body>
                            <Card.Title>Hormonal Sorunlar</Card.Title>
                            <Card.Text>
                              Hormonlar cinsel, duygusal ve fizyolojik
                              sağlığımızda rol oynar. Kadın ve erkek sağlığından
                              beyin sisi, hafızaya, isteksizlik, depresyon,
                              anksiyete ve daha fazlasına kadar hormonlar
                              vücuttaki birçok bezi ve nörotransmitteri etkiler.
                              Yaygın Hormonal Sorunlar:
                              <ul>
                                <li>Düşük Testesteron</li>
                                <li>Kronik Adrenal Yorgunluk</li>
                                <li>Hipotroidizm</li>
                                <li>Polikistik Over Sendromu</li>
                                <li>Menopoz</li>
                              </ul>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={6} className="d-flex">
                        <Card className="mb-4 flex-fill">
                          <Card.Body>
                            <Card.Title>Gastrointestinal Sorunlar</Card.Title>
                            <Card.Text>
                              Bağırsak mikrobiyomundaki dengesizlikler cilt
                              rahatsızlıkları, otoimmün bozukluklar, bağışıklık
                              sistemi dengesizlikleri ve çoklu enflamatuar
                              bozukluklar gibi sorunlara yol açabilir.
                              Gastrointestinal sistemde yaşayan bakterileri
                              bilmek ve anlamak önemlidir. Yaygın
                              Gastrointestinal Sorunlar:
                              <ul>
                                <li>
                                  Sızdıran bağırsak, bağırsak geçirgenliği
                                  sendromu
                                </li>
                                <li>Gastroözofageal Reflü Hastalığı</li>
                                <li>İrritabl Bağırsak Sendromu</li>
                                <li>Gastrit</li>
                                <li>Kronik kabızlık, ishal</li>
                              </ul>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={6} className="d-flex">
                        <Card className="mb-4 flex-fill">
                          <Card.Body>
                            <Card.Title>Gıda Alerjisi</Card.Title>
                            <Card.Text>
                              Gıda Alerji Belirtileri:
                              <ul>
                                <li>Ağızda kaşıntı</li>
                                <li>Kurdeşen, egzama, kaşıntı</li>
                                <li>Dudak, dil veya boğaz şişmesi</li>
                              </ul>
                              Gıda Duyarlılığı Belirtileri:
                              <ul>
                                <li>Baş ağrıları</li>
                                <li>Gaz, kramp veya şişkinlik</li>
                                <li>Sinirlilik veya ruh hali değişiklikleri</li>
                              </ul>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md={6} className="d-flex">
                        <Card className="mb-4 flex-fill">
                          <Card.Body>
                            <Card.Title>İnflamatuar Sorunlar</Card.Title>
                            <Card.Text>
                              İnflamasyon, vücudunuzun bağışıklık sisteminin
                              zararlı şeylere ve yaralanmalara verdiği normal
                              tepkidir. Bağışıklık hücreleri, sorunu çözmek için
                              hasarlı bölgeye hızla tepki verir. Vücut sürekli
                              olarak "düzelt" modunda olduğunda, artan
                              iltihaplanma yanıtı birçok istenmeyen duruma ve
                              semptoma neden olur. Yaygın inflamatuar sorunlar:
                              <ul>
                                <li>Eklem ağrısı</li>
                                <li>İnflamatuar Bağırsak Hastalığı</li>
                                <li>
                                  Döküntüler, Egzama, Akne, Sedef Hastalığı
                                </li>
                                <li>Alerjiler</li>
                                <li>Romatoid Artrit</li>
                              </ul>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Fonksiyonel Tıp Uygularken İleri Tanısal Testlerin
                            Rolü
                          </Card.Title>
                          <Card.Text>
                            En güncel ve güvenilir test seçeneklerini sunmak
                            için yurtiçi ve yurtdışı çeşitli uzmanlık
                            laboratuvarlarıyla iletişim halindeyiz.Gelişmiş tanı
                            testlerini kullanarak, sağlık endişelerinizin temel
                            nedenini daha iyi araştırabilir, tanımlayabilir ve
                            ele alabiliriz. Yurtdışı laboratuvar destekli test
                            seçeneklerimiz ile gerçekleştirdiğimiz laboratuvar
                            çalışması, geleneksel bakış açılı laboratuvar
                            çalışmasından farklıdır. Kanıtlanmış bilime dayanan
                            gelişmiş tanı testlerimiz size bedeniniz hakkında
                            çok daha fazla şey anlatacak ve metabolik
                            sistemlerinizin sağlığını çok daha kapsamlı bir
                            şekilde ifade eden yönlerini ölçecektir. Gelişmiş
                            tanı testlerini tercih ederek hedefleri sonuca
                            dönüştürebilirsiniz.
                          </Card.Text>
                          <Card.Text>
                            <h5>
                              Gelişmiş tanısal testler sizin için ne kadar
                              gerekli?
                            </h5>
                          </Card.Text>
                          <Card.Text>
                            Vücudumuz karmaşıktır, bu nedenle farklı kök
                            nedenler, belirli bir hastalık için tipik veya
                            düzensiz görünen semptomlar olarak ortaya
                            çıkabilir.Bu durum özellikle bir hastalık veya işlev
                            bozukluğu kaynağı diğeriyle etkileşime giriyorsa
                            daha da geçerlidir.Bu eksiklikleri keşfetmenin en
                            iyi yolu doğru anamnez kadar gelişmiş tanı testleri
                            kullanmaktır.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Akut Sorunlara Fonksiyonel Çözüm Merkezi
                          </Card.Title>
                          <Card.Text>
                            Günlük sağlık rutininizde fonksiyonel tıp uygularken
                            bazı akut sorunlarda bunu değiştirmeli misiniz? Bu
                            amaçla ofiste,
                            <ul>
                              <li>IV Terapi</li>
                              <li>Enjeksiyonlar</li>
                              <li>COVID test ve tedavileri</li>
                              <li>Nebulize tedaviler</li>
                              <li>Farmasötik grup takviyeler</li>
                              <li>Reçeteli ilaçlar</li>
                              <li>Doğal ilaçlar</li>
                            </ul>
                            uyguluyoruz.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    FONKSİYONEL KONSÜLTASYON
                  </Card.Title>

                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Text>
                          Fonksiyonel Tıp yaklaşımı, yaptığımız işin her
                          alanında kullanılırken, her hastanın farklı olduğunu
                          ve benzersiz vakaların özelliklerine göre
                          kişiselleştirilmiş dikkat gerektirdiğini biliyoruz.
                        </Card.Text>
                        <Card.Text>
                          Fonksiyonel Konsültasyon, kapsamlı araştırma ve
                          değerlendirmeye ihtiyaç duyan karmaşık sorunları olan
                          hastalar için tasarlanmıştır. Çok sayıda hekime
                          gitmiş, çeşitli tedaviler deneyen, semptomların
                          sebebini anlamaya çalışmak için sayısız saat ve emek
                          harcayan hastalar görüyoruz.Tipik olarak bu tür
                          karmaşık vakalar bir yandan şikayetleri devam ederken
                          bir yandan bir hekimden diğer hekime gitmeye devam
                          ederler. Bu döngünün bizimle kırılmasını hedefliyoruz.
                        </Card.Text>
                        <Card.Text>
                          Yaklaşımımız, semptomların ve sağlık sorunlarının
                          temel nedenini gerçek anlamda belirlemek ve ele
                          almaktır.İster semptomlarınızı ilk kez araştırıyor
                          olun, ister bir dizi kapsamlı testten geçmiş olun,
                          karmaşık vakalar genelde "Bünyeniz yapıyor" ya da
                          "psikolojik" denilen yanıtlar ile karşılaşıyor.
                          Derinlemesine Fonksiyonel Konsültasyon sürecimiz,
                          sağlığımızın köklerinin nereye ekildiğini ve
                          geleceğimizin yörüngesini nasıl değiştireceğimizi
                          ortaya çıkarmayı hedeflemektedir. Bu amaçla
                          hastalarımıza ilk görüşmede 90 dakika, takip
                          görüşmelerinde 45 dakika süre ayırıyoruz.Bazen süreyi
                          daha da uzatmak gerekebiliyor.
                        </Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          Ne beklemelisiniz?
                        </Card.Subtitle>
                        <Card.Text>
                          <ul>
                            <li>Sağlık öykünüzün kapsamlı değerlendirilmesi</li>
                            <li>Gelişmiş testlerin uygulanması</li>
                            <li>
                              Laboratuvar bulgularınızın kişiselleştirilmiş
                              incelemesi
                            </li>
                            <li>Size özel tavsiyeler ve tedavi planı</li>
                            <li>Takviyeler ve IV Terapi desteği</li>
                            <li>Takip</li>
                          </ul>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title>Yolculuğunuz</Card.Title>
                        <Card.Text>
                          Belirtilerinizin olası temel nedenini belirledikten
                          sonra, uzun vadeli sağlık yolunda dengeyi sağlamak ve
                          korumak istiyoruz.İyileşme gördükçe sürekli test ve
                          müdahale ihtiyacı giderek azalıyor.Amaç onarmak ve
                          devamlılığı sağlamak. Başlangıçta daha sık görüşme
                          sağlanıp daha sonra 3-6 ayda bir takip
                          edileceksiniz.Bu süreç, değişiklikleri izlememize,
                          ilerlemeyi değerlendirmemize ve vücudunuzun tedavi
                          edici müdahalelere nasıl tepki verdiğini tercüme
                          etmemize olanak sağlayacaktır.
                        </Card.Text>
                        <Card.Text>
                          Geleneksel sistemdeki deneyiminizin aksine ,
                          hikayenizin nasıl geliştiğini bilmek ve
                          karşılaştığımız herhangi bir zorluğun üstesinden
                          gelebilmeyi isteriz. Hastalarımızla birlikte
                          sorunlarının kaynağını bulmak için birlikte
                          çalışıyoruz. Hasta programlarımızı ilerlemenizle
                          birlikte hareket etmek için tasarladık.Sizin için en
                          geçerli noktaya ulaştığımızda , sağlıklı
                          alışkanlıkların yaşam tarzı boyunca sürdürüldüğü
                          bütünleştirici ve kontrol edici ID-Check Up (İleri
                          Diyagnostik Check Up) programına doğru ilerlemeye
                          başlarsınız.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title>
                          Hasta Programına Dahil Olabilecekler
                        </Card.Title>
                        <Card>
                          <Card.Body>
                            <Card.Title>
                              1. Bütünleştirici Kilo Yönetimi
                            </Card.Title>
                            <Card.Text>
                              <h6>
                                Sağlıklı biri bu kadar kilolu hissetmek
                                durumunda değil.
                              </h6>{" "}
                              Kilo vermek imkansız görünüyorsa, yalnız
                              olmadığınızdan emin olabilirsiniz.Kilo alımı,
                              birçok farklı sağlık sorununun yaygın bir
                              belirtisidir. Bütünsel bir fonksiyonel tıp
                              yaklaşımını benimseyerek, davranış şeklinizi tıp
                              bilimi ile birleştirerek, hastalarımız kilo alma
                              zincirinden kurtulurlar.
                            </Card.Text>
                            <Card.Text>
                              Bazıları için temel beslenme ve yaşam tarzı
                              eğitimi, kilo sorunlarını çözen bütünleştirici bir
                              plan oluşumunu sağlayabilir. Diğerleri için , bu
                              çözüme ulaşmayı engelleyen altta yatan tıbbi
                              sorunları ele alan bir fonksiyonel tıp çalışması
                              gereklidir. Bu durumda fonksiyonel tıp hekimimiz ,
                              kilo alımının yüksek olduğu koşulları araştırırken
                              tıbbi kilo kaybını bir öncelik haline getiren bir
                              plan oluşturacaktır. Bu süreç, hedeflerine ve
                              yaşam tarzına göre her hasta için farklı görünür.
                            </Card.Text>
                            <Card.Text>
                              Bütüncül kilo verme programımız, fonksiyonel tıp
                              hekimimiz ile kapsamlı bir geçmiş ve fiziksel
                              muayene ile başlar.Gelişmiş tanı testimiz,
                              vücudunuzun hormonları, vitaminleri ve besinleri
                              nasıl işlediği hakkında bilgi sağlayacaktır .
                              İnsülin ve leptin dahil olmak üzere sindirim
                              sisteminin açlık ve metabolik hormonlarını
                              optimize etmek için çalışacağız. Doğal takviyeler
                              veya medikal destekler kullanmak, iştahı kontrol
                              etmeye ve metabolizma işlevini arttırmaya yardımcı
                              olabilir.
                            </Card.Text>
                            <Card.Text>
                              Her hastanın başarısının , vücudunun
                              değişikliklere nasıl tepki verdiğine bağlı
                              olduğunu anlıyoruz. Amacımız toksinleri ortadan
                              kaldırmak, inflamasyonu azaltmak ve dengeyi
                              yeniden sağlamak için dengeli, bütüncül bir plan
                              oluşturmaktır. Siz sağlıklı bir kiloya ve
                              hedeflerinize ulaşana kadar bu sürecin her
                              adımında sizinle işbirliği halinde
                              olacağız.Tıkandığınız noktalarda durum
                              değerlendirmesini yine sizinle birlikte yapıp
                              gerekli revizyonları uygulayacağız.
                            </Card.Text>
                            <div>
                              {[1, 2, 3, 4].map((step) => (
                                <Card key={step} className="mb-3">
                                  <Card.Body>
                                    <Card.Title>Adım {step}</Card.Title>
                                    <Card.Text>
                                      {step === 1 &&
                                        "İlk adımı atarak yeni yolculuğunuza başlayın. Size yardım etmeye hazırız! Önce sağlık geçmişinizi gözden geçirecek, yolculuğunuzda nerede olduğunuzu öğrenecek ve en etkili eylem planınızın ne olacağına karar vermek için sizinle birlikte yol alacağız."}
                                      {step === 2 &&
                                        "Laboratuvar  analizleri bu planın oluşumunun  önemli bir  parçasıdır. Özel testler sayesinde eksiklikleri ve dengesizlikleri ortaya çıkarabileceğiz. Laboratuvar bulguları sayesinde , hedeflerinize ulaşmak için neyin ele alınması gerektiğini belirleyebiliyoruz."}
                                      {step === 3 &&
                                        "Fonksiyonel tıp hekimimiz  sizin için uygun  kişisel bir plan oluşturmak için sizinle birlikte çalışacaktır. Beslenme planları, yaşam tarzı planları ve ek programlar bizimle olan yolculuğunuza dahildir. Sizin için  özel olarak hazırlanmış bu süreci yönetmeyi de öğreneceksiniz."}
                                      {step === 4 &&
                                        "Kilo sorunlarınızın altında yatan  nedeni ortaya çıkararak , restorasyona yönelik adımlar atmaya başlayacağız. Kilo sorunları hayatınızı en iyi şekilde yaşamanızı engelleyen, ömür boyu sürecek bir durum olmamalıdır. Optimizasyon mümkündür."}
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              ))}
                            </div>
                          </Card.Body>
                        </Card>
                        <Card className="mt-4">
                          <Card.Body>
                            <Card.Title>
                              Fonksiyonel Beslenme Eğitimi
                            </Card.Title>
                            <Card.Text>
                              Besinler biyomekanik motorumuzu çalıştıran
                              yakıtlardır. Genetiği değiştirilmiş gıdalar ve
                              gıda üretimini düzenleyen sistemler dolayısıyla
                              "ne yersek oyuz" ifadesi daha da önemli bir hal
                              almıştır. Hedefe ulaşmak için ne yediğimiz, ne
                              kadar yememiz gerektiği konusunda bilgi almak ve
                              beslenmenin sağlığımızın temeli olduğunu keşfetmek
                              çok önemlidir.
                            </Card.Text>
                            <Card.Text>
                              Integratif Fonksiyonel Medikal (IFM) beslenme
                              programı , çok çeşitli tanı ve durumları tedavi
                              edebilen çok yönlü bir süreçtir. Fonksiyonel tıp
                              hekimi tarafından kişiye özel hazırlanan
                              fonksiyonel beslenme planı, metabolizma
                              düzenleyici kilo kaybını sağlayan, obezitenin
                              nedenlerini, önlenmesini ve tedavisini içeren bir
                              programdır. Bu program ile metabolizmanız
                              dengelenirken yan etki olarak kilo vermeye
                              başlarsınız.
                            </Card.Text>
                            <Card.Text>
                              <b>Metod:</b> Danışanın detaylı anamnez ve fizik
                              muayene sonrası metabolik ve genetik bilgilerine
                              dayanarak tamamen ihtiyacına göre belirlenen,
                              sosyal yaşam şeklinin de değerlendirmeye alındığı
                              sürdürülebilir bir fonksiyonel beslenme planı
                              oluşturulur. Bu plan, danışan - fonksiyonel tıp
                              hekimi işbirliği ile hazırlanır. Takip süreci
                              başladıktan sonra her danışan bedeninin kendi
                              planına vereceği yanıt değişkenlik gösterebilir.
                              Sağlıklı kilo verme , eş zamanlı metabolik
                              iyileşme, sağlık sorunlarının çözülmesi vb
                              hedeflere ulaşmak için oral ve damar içi
                              takviyeler de plana dahil edilir. Bazı durumlarda
                              bu takviyeler fonksiyonel tıp hekimimiz tarafından
                              sürecin başında eklenir. Size özel yaklaşımların
                              uygulanması, hedefinize ulaşmanızı ve gelecekte de
                              kilonuzu korumanızı destekleyecek en başarılı
                              yaklaşım olacaktır. Her şey tamamen danışan ve
                              fonksiyonel tıp hekimi işbirliği ile uygulanır.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title>2. Biyoeşdeğer Hormon Tedavisi</Card.Title>
                        <Card.Text>
                          Canlılığı yeniden keşfetmenize , dengeye ulaşmanıza ve
                          yaşamın her alanında gelişmenize yardımcı olmak için
                          kişisel olarak hazırlanmış Hormon Replasman Tedavisi
                          hizmetimizin faydalarından istifade edin.
                        </Card.Text>
                        <Card.Text>
                          Geleneksel olarak, Hormon Replasman Tedavisi sadece
                          menopoz sonrası kadınlarda östrojen ve progesteronun
                          dengelenmesi içindi, ancak yaşlanma önleyici bilimsel
                          ilerlemeler hem erkekler hem de kadınlar için
                          biyolojik olarak özdeş hormon replasmanının
                          kullanımını yaygınlaştırdı. Kadınlar sadece
                          hayatlarını daha eksiksiz bir hormon replasmanı ile
                          yenilemekle kalmıyor, aynı zamanda erkekler de
                          biyolojik hormonların bedenlerini ve zihinlerinin
                          gelişmesine yardımcı olabileceğini ve gençliklerinin
                          enerjisini, libidosunu ve canlılığını yeniden ele
                          almalarına yardımcı olabileceğini keşfediyor.
                        </Card.Text>
                        <Card.Text>
                          Biyoeşdeğer hormon replasman tedavisi, hem erkeklerin
                          hem de kadınların hormon düşüşü ile yaşadığı yaygın
                          semptomları tedavi etmek için kanıtlanmış etkili bir
                          yöntemdir. Biyoözdeş hormonlar, moleküler yapı ve
                          işlev bakımından insan vücudunda üretilen hormonlarla
                          aynı olmaları bakımından benzersizdir, bu da onları
                          metabolize etmeyi ve dengesizlikleri düzeltmeyi
                          kolaylaştırabilir. Biyo-eşdeğer hormonların hepsi
                          doğaldır. Bir hormon değerlendirmesi ile
                          hastalarımızın hem fiziksel hem de duygusal olarak
                          daha genç ve daha enerjik hissetmelerine yardımcı
                          olacak çözümler bulabiliriz.
                        </Card.Text>
                        <Card.Text>
                          <strong>Kişiye Özel Tedavi Planları:</strong>
                          İlerlemelerinizi dikkatli bir şekilde takip ediyoruz,
                          gerektiğinde ince ayar tedavileri ve her dönemeçte
                          destek almanızı önemsiyoruz. HRT'nin avantajlarını
                          kavramanızı ve iyi bilgilendirilmiş karar vermeyi
                          teşvik etmenizi sağlayarak eğitime öncelik veririz.
                        </Card.Text>
                        <Card.Text>
                          Biyoeşdeğer hormonlar, vücudunuz tarafından doğal
                          olarak üretilen hormonların eksikliğini yerine koymak
                          için üretilmiştir. Biyo-özdeş hormonların
                          kullanılması, hormon dengesizliği, perimenopoz ve
                          menopoz dönemlerinde yaygın görülen düşük veya
                          dengesiz hormon semptomlarıyla boğuşan bireylere
                          rahatlama sağlayabilir.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title
                          style={{ textAlign: "center", cursor: "pointer" }}
                          onClick={openModal2}
                        >
                          CİNSİYET VE STRES HORMONLARI İLE İLGİLİ DETAY İÇİN
                          TIKLAYINIZ
                        </Card.Title>
                      </Card.Body>
                    </Card>
                    <MyPDFViewer
                      pdfFile={cinsiyetvestres}
                      isOpen={showViewer2}
                      onClose={closeModal2}
                    />
                  </Col>
                  <Col md={12} className="d-flex">
                    <Card className="mb-4 flex-fill">
                      <Card.Body>
                        <Card.Title>Hangisi Sizi Tarif Ediyor?</Card.Title>
                        <Row>
                          <Col md={4}>
                            <ListGroup>
                              <ListGroup.Item>Yorgunluk</ListGroup.Item>
                              <ListGroup.Item>Yüksek kan şekeri</ListGroup.Item>
                              <ListGroup.Item>Uykusuzluk</ListGroup.Item>
                              <ListGroup.Item>
                                Enfeksiyonla savaşamama
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Azalan bellek, tepki süresi, problem çözme
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Ateş yükselme hissi
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Egzersizle bile yağ kaybedememek
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Kilo alımı ve yavaş metabolizma
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Soğuk eller ve ayaklar
                              </ListGroup.Item>
                              <ListGroup.Item>Kronik kabızlık</ListGroup.Item>
                              <ListGroup.Item>Kas güçsüzlüğü</ListGroup.Item>
                              <ListGroup.Item>Depresyon</ListGroup.Item>
                            </ListGroup>
                          </Col>
                          <Col md={4}>
                            <ListGroup>
                              <ListGroup.Item>
                                Yüksek kolesterol ve kalp hastalığı riski
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Dayanıklılık kaybı
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Apati, zihinsel sis
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Zayıf kasların kaybı
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Yüksek tansiyon, kan şekeri
                              </ListGroup.Item>
                              <ListGroup.Item>Sıcak basmaları</ListGroup.Item>
                              <ListGroup.Item>Gece terlemeleri</ListGroup.Item>
                              <ListGroup.Item>Vajinal kuruluk</ListGroup.Item>
                              <ListGroup.Item>Eklem ağrıları</ListGroup.Item>
                              <ListGroup.Item>Düşük Libido</ListGroup.Item>
                              <ListGroup.Item>
                                Stres inkontinansı
                              </ListGroup.Item>
                            </ListGroup>
                          </Col>
                          <Col md={4}>
                            <ListGroup>
                              <ListGroup.Item>
                                Tekrarlayan İdrar Yolu Enfeksiyonları
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Uykusuzluk veya verimsiz uyku
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Özellikle gece kaşıntılı ve huzursuz bacaklar
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Anksiyete, sinirlilik, depresyon
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Karın ve ayak bileklerinde şişkinlik
                              </ListGroup.Item>
                              <ListGroup.Item>Kilo verememe</ListGroup.Item>
                              <ListGroup.Item>
                                Erektil Disfonksiyon
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Kas kütlesi ve güç kaybı
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Artan uyku ihtiyacı
                              </ListGroup.Item>
                              <ListGroup.Item>Sinirlilik</ListGroup.Item>
                              <ListGroup.Item>Beyin sisi</ListGroup.Item>
                              <ListGroup.Item>
                                Kilo alımı ve artan karın yağı
                              </ListGroup.Item>
                            </ListGroup>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12} className="d-flex">
                    <Card className="mb-4 flex-fill">
                      <Card.Body>
                        <Card.Title>Hangisi İlginizi Çekiyor?</Card.Title>
                        <Row>
                          <Col md={6}>
                            <ListGroup>
                              <ListGroup.Item action href="/idCheckUp">
                                ID Check Up.Wellness
                              </ListGroup.Item>
                              <ListGroup.Item action href="/rejaneratif">
                                Rejeneratif Konsultasyon
                              </ListGroup.Item>
                            </ListGroup>
                          </Col>
                          <Col md={6}>
                            <ListGroup>
                              <ListGroup.Item action href="/butunselGuzellik">
                                Cilt ve Ötesi
                              </ListGroup.Item>
                              <ListGroup.Item action href="/intravenoz">
                                IV Terapiler
                              </ListGroup.Item>
                            </ListGroup>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Footeralt />
      </div>
    </div>
  );
}

export default FonksiyonelTıp;
