import React, { useState, useEffect } from 'react';
import './detailsPhoto.css';
import cevap from "../images/1.jpg";
import ihtiyac from "../images/2.jpg";
import hak from "../images/3.jpg";

const ImageGallery = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [description, setDescription] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); 
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleImageClick = (desc) => {
    if (showOverlay && description === desc) {
      setShowOverlay(false);
    } else {
      setDescription(desc);
      setShowOverlay(true);
    }
  };

  const handleMouseEnter = (desc) => {
    setDescription(desc);
    setShowOverlay(true);
  };

  const handleMouseLeave = () => {
    setShowOverlay(false);
  };

  return (
    <div className="image-gallery mt-5 p-5" style={{ backgroundColor: '#d9d9d9' }}>
      {['ihtiyac', 'cevap', 'hak'].map((image, index) => (
        <div
          key={index}
          className={`image-container ${isMobile ? 'mobile-layout' : ''}`}
          onMouseEnter={() => !isMobile && handleMouseEnter(descriptions[index])}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleImageClick(descriptions[index])}
        >
          <img src={images[index]} alt={`Fonksiyonel Tıp ve Rejeneratif Tıp alanında uzmanlaşmış ${index + 1}. resim`} className="gallery-image" />
          {showOverlay && (
            <div className={`image-overlay ${isMobile ? 'mobile-text' : ''}`}>
              <span className="overlay-text">{description}</span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const descriptions = [
  'Beraberinde yeni yan etkilerin olduğu tedavi protokolü uygulama deneyiminiz oldu ise, daha iyi değerlendirilmeyi hak ediyorsunuz. Hekimimiz, sağlık hedeflerinize ulaşmanıza yardımcı olacak etkili, bütünleştirici tedavi seçenekleri sunma konusunda tecrübelidir.',
  'Doktor muayenehanesinden zihninizde cevapsız çok fazla soru ile ayrıldıysanız, ne kadar kararsız olabileceğinizi anlıyoruz. Ayrıca daha iyisini hak ettiğinizi de biliyoruz. Hekimimiz, danışanların sağlık yolculuklarında nerede olduğunu gerçekten görmek, dinlemek ve anlamak için zaman ayırır ve bütünleştirici bir Fonksiyonel Tıp yaklaşımı ile kök sebebi bulmaya yönelik davranır.',
  'Sayısız doktora gittiyseniz ve aldığınız zamanın ve ilginin sınırlı olduğunu hissettiyseniz, yalnız değilsiniz. Daha iyisini hak ettiğinizi biliyoruz. Hekimimiz, tanıştığı her danışan için gerçekten kişiselleştirilmiş bir deneyim sağlamak üzere deneyimlidir.'
];

const images = [
  ihtiyac,
  cevap,
  hak
];

export default ImageGallery;