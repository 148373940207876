import React, { useEffect, useState } from "react";
import TopCard from "../components/topCard/TopCard";
import CustomNavbar from "../components/navbar/Navbar";
import { Card, Col, Container, Row } from "react-bootstrap";
import Footeralt from "../components/footer2/Footeralt";
import hearthmath from "../components/documents/hearthmath.pdf";
import stres from "../components/images/stresli.png";
import huzur from "../components/images/huzurlu.png";
import MyPDFViewer from "../components/pdfViewer";
import {
  FaHeart,
  FaBalanceScale,
  FaEye,
  FaHandsHelping,
  FaSmile,
  FaCheck,
  FaUsers,
  FaSeedling,
  FaSun,
  FaThumbsUp,
  FaAnchor,
} from "react-icons/fa";

function HeartMath() {
  const [showViewer, setShowViewer] = useState(false);
  const [topCardHeight, setTopCardHeight] = useState(0);
  const openModal = () => {
    setShowViewer(true);
    document.body.style.overflow = "hidden";
  };
  const closeModal = () => {
    setShowViewer(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const topCardElement = document.querySelector(".top-card");
    if (topCardElement) {
      const topCardRect = topCardElement.getBoundingClientRect();
      setTopCardHeight(topCardRect.height);
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position = "relative";
        navbarElement.style.backgroundColor = "grey";
      }
    }

    function handleScroll() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position =
          scrollY > topCardHeight ? "fixed" : "relative";
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topCardHeight]);

  const maddeler = [
    { icon: FaHeart, text: "Endişe ve Kaygıyı Azaltın" },
    { icon: FaBalanceScale, text: "Dengenizi kazanın" },
    { icon: FaEye, text: "Bakış Açınızı Değiştirin" },
    { icon: FaHandsHelping, text: "Geleceğiniz için umudunuzu kaybetmeyin" },
    { icon: FaSmile, text: "Kalbinizde sevgiye yer açın" },
    { icon: FaCheck, text: "Doğru Seçimler Yapmayı Deneyimleyin" },
    {
      icon: FaThumbsUp,
      text: "Sabır, şükran, nezaket ve daha fazlasını hissedebilirsiniz",
    },
    { icon: FaHeart, text: "Endişe ve kaygıyı azaltın" },
    { icon: FaUsers, text: "Takım arkadaşlarınızla uyumu deneyimleyin" },
    { icon: FaSeedling, text: "İçsel durgunluğunuzu keşfedin" },
    { icon: FaSun, text: "Stresten bunalıp amacınızı unutmayın" },
    { icon: FaAnchor, text: "Gelecek için umudunuzu yitirmeyin" },
  ];

  return (
    <div>
      <TopCard className="top-card" />
      <div className="navbar-fixed">
        <CustomNavbar />
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-3">
          <Row>
            <Col md={12}>
              <Card className="pt-3 mb-4">
                <Card.Title style={{ textAlign: "center" }}>
                  HEART MATH - STRES YÖNETİMİ İLE İÇ DENGEYİ BULMA
                </Card.Title>
                <Card.Body>
                  <Col md={12}>
                    <Card className="pt-3 mb-4">
                      <Card.Body>
                        <Row className="justify-content-center">
                          <Col md={6} className="d-flex justify-content-center">
                            <ul className="list-unstyled text-center">
                              <li>Stresi Serbest Bırak.</li>
                              <li>Dengeyi Bul</li>
                            </ul>
                          </Col>
                          <Col md={6} className="d-flex justify-content-center">
                            <ul className="list-unstyled text-center">
                              <li>Odaklan</li>
                              <li>Gerçekleştir</li>
                            </ul>
                          </Col>
                        </Row>
                        <Card.Text>
                          İş stresi, görevi zamanında tamamlama, yöneticiniz ile
                          yaşanan iletişim sorunları Aile ilişkilerinde yaşanan
                          stresler, boşanma ya da evlilik öncesi dönemde olmak
                          Uzun süreli hastalık, yaralanma ile baş etme. Doğal
                          afet nedeniyle travma yaşama. Askere gitmek. Topluluk
                          önünde konuşma öncesi Sınavlar öncesi dönem Başka
                          şehre taşınma gibi durumlar strese neden olabilir.
                          Zihinsel ve duygusal sağlığınızı yükseltin
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title>
                          Zihinsel ve Duygusal Sağlığınızı Yükseltin
                        </Card.Title>
                        <Card.Text>
                          Stres anında duygusal durumunuzu yükseltmeyi ve
                          birikmiş stresin farkına varmayı öğrenin ve serbest
                          bırakın, böylece daha iyi hissedersiniz ve daha net
                          düşünürsünüz. 30 yıllık kalp-beyin araştırmasıyla
                          desteklenen Heart Math teknolojisi sizi, kalbinizi ve
                          beyninizi senkronize etmek için eğitir, bu da stresi,
                          kaygıyı önler ve azaltır, sağlığı ve performansı
                          iyileştirir ve daha doğru kararlar vermeniz için
                          düşüncelerinizi netleştirir. Sadece birkaç dakikalık
                          günlük uygulama ile beyin- kalp uyumunuzu, daha
                          dengeli, etkili ve sağlıklı yaşam için kolayca elde
                          edebilir hale gelebilirsiniz. Zamanla, fonksiyonel tıp
                          hekiminiz olmadan bile hayatınızın her alanında
                          uygulayabileceğiniz bir beceri kazanabilirsiniz.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title>HeartMath® Teknolojisi</Card.Title>
                        <Card.Text>
                          HeartMath®, stres, duygular ve kalp ile beyin
                          arasındaki etkileşimlerin psikofizyolojisi üzerine
                          yapılan 30 yılı aşkın bilimsel araştırmaya
                          dayanmaktadır. HeartMath teknolojisi, duygusal iyilik
                          halini geliştirmek için yenilikçi bir yaklaşımdır.
                          Fizyolojik tutarlılık oluşturmak için kalp ritmi
                          modelinizi değiştirmeyi öğrenin. Bunun zihninizde,
                          duygularınızda ve bedeninizde artan düzen ve uyum ile
                          karakterize edilen bilimsel olarak ölçülebilir bir
                          durum olduğunu Fonksiyonel Tıp hekimimiz ile
                          yapacağınız HeartMath® test ve egzersiz seansınızda
                          deneyimleyebilirsiniz.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title>Nefes Almanın Rolü</Card.Title>
                        <Card.Text>
                          Bir diğer önemli konu, tutarlılık üretiminde nefes
                          almanın rolünü ve bunun HeartMath Sistemi
                          teknikleriyle ilişkisini anlamayı içerir. Nefes alma
                          kalıpları kalbin ritmini modüle ettiğinden, 10
                          saniyelik bir ritimde yavaş ve düzenli olarak nefes
                          alarak (nefes içinde 5 saniye ve nefeste 5 saniye)
                          tutarlı bir kalp ritmi oluşturmak mümkündür. Bu
                          şekilde ritmik olarak nefes almak, bu nedenle stresli
                          duygusal durumdan çıkıp artan tutarlılığa geçişi
                          başlatmak için yararlı bir müdahale olabilir. Bununla
                          birlikte, bu tür bilişsel olarak yönlendirilmiş
                          tempolu nefes alma, önemli ölçüde zihinsel çaba
                          gerektirebilir ve bazı insanların sürdürmesi zordur.
                          HeartMath teknikleri bir nefes alma unsuru içerse de,
                          tempolu nefes alma birincil odak noktası değildir ve
                          bu nedenle sadece nefes egzersizleri olarak
                          düşünülmemelidir. HeartMath araçları ve en yaygın
                          olarak uygulanan nefes alma teknikleri arasındaki
                          temel fark, HeartMath tekniğinin içten pozitif bir
                          duygusal durumun kasıtlı olarak üretilmesine
                          odaklanmasıdır. Bu duygusal değişim, tekniğin
                          etkinliğinin önemli bir kısmıdır. Olumlu duygular,
                          sistemi doğal rezonans frekansında heyecanlandırıyor
                          gibi görünür ve böylece kişinin nefes ritmine bilinçli
                          zihinsel odaklanmadan tutarlılığın ortaya çıkmasını ve
                          doğal olarak korunmasını sağlar. Bunun nedeni, kalbin
                          ritmik aktivitesi tarafından üretilen girdilerin
                          aslında solunum hızımızı ve kalıplarımızı etkileyen
                          ana faktörlerden biri olmasıdır. Kalbin ritmi, pozitif
                          bir duygusal değişimin bir sonucu olarak tutarlılığa
                          geçtiğinde, nefes ritmimiz otomatik olarak kalple
                          senkronize olur, böylece sistemin tutarlılığa geçişini
                          güçlendirir ve stabilize eder.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title>
                          Fonksiyonel Tıp Hekimimiz ile Uygulama
                        </Card.Title>
                        <Card.Text>
                          HeartMath tekniğinin tüm faydalarını elde etmek için,
                          kendi kendinizi nasıl etkinleştireceğinizi ve sonunda
                          olumlu bir duyguyu nasıl sürdüreceğinizi öğrenmek
                          önemlidir. Fonksiyonel tıp hekimimiz, başlangıçta
                          tutarlılığı elde etmek veya sürdürmeyi sağlamak için
                          önce sizi bir uygulama testine tabi tutar. Sonucunuza
                          göre eğitiminizi verir ve eş zamanlı uygulamaya
                          başlarsınız. Bireyler ritmik nefes alarak tutarlılık
                          oluşturmaya ve bu durumun nasıl hissettiğine aşina
                          olduklarında, kalp bölgesinden olumlu bir duygu veya
                          tutum ile nefes almaya başlayabilirler. Sonunda,
                          uygulamanın sürekliliği ile çoğu insan, olumlu bir
                          duyguyu doğrudan harekete geçirerek tutarlılığa
                          geçebilir hale gelir. Deneyimlemek için randevu….
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title style={{ textAlign: "center" }}>
                          Sizi Hangisi Tarif Ediyor?
                        </Card.Title>
                        <Row>
                          <Col md={6}>
                            <Card.Title style={{ textAlign: "center" }}>
                              Stresli
                            </Card.Title>
                            <Card.Img src={stres} />
                          </Col>
                          <Col md={6}>
                            <Card.Title style={{ textAlign: "center" }}>
                              Huzurlu
                            </Card.Title>
                            <Card.Img src={huzur} />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title style={{ textAlign: "center" }}>
                          KALP MATEMATİĞİ İLE
                        </Card.Title>
                        <Row>
                          {maddeler.map((madde, index) => (
                            <Col
                              md={4}
                              className="text-center mb-3"
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <madde.icon size={40} />
                              <p
                                style={{
                                  marginTop: "10px",
                                  textAlign: "center",
                                }}
                              >
                                {madde.text}
                              </p>
                            </Col>
                          ))}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Card.Title
                          style={{ textAlign: "center", cursor: "pointer" }}
                          onClick={openModal}
                        >
                          Detaylı bilgi için tıklayınız...
                        </Card.Title>
                      </Card.Body>
                    </Card>
                    <MyPDFViewer
                      pdfFile={hearthmath}
                      isOpen={showViewer}
                      onClose={closeModal}
                    />
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Footeralt />
      </div>
    </div>
  );
}

export default HeartMath;
