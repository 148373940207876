import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import "@react-pdf-viewer/core/lib/styles/index.css";
import TopCard from "../components/topCard/TopCard";
import CustomNavbar from "../components/navbar/Navbar";
import Footeralt from "../components/footer2/Footeralt";
import "./Pages.css";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function KadınVeCocuk() {
  const [topCardHeight, setTopCardHeight] = useState(0);

  useEffect(() => {
    const topCardElement = document.querySelector(".top-card");
    if (topCardElement) {
      const topCardRect = topCardElement.getBoundingClientRect();
      setTopCardHeight(topCardRect.height);
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position = "relative";
        navbarElement.style.backgroundColor = "grey";
      }
    }

    function handleScroll() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position =
          scrollY > topCardHeight ? "fixed" : "relative";
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topCardHeight]);

  return (
    <div>
      <TopCard className="top-card" />
      <div className="navbar-fixed">
        <CustomNavbar />
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container>
          <Row>
            <Col md={12}>
              <Card className="mb-4 mt-3">
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>BÜTÜNCÜL ÇOCUK SAĞLIĞI</Card.Title>
                          <Card.Text>
                            Bütüncül Çocuk Sağlığı Programı, çocuğunuz için
                            mevcut yaşından yetişkinliğe kadar sağlıklı ve zinde
                            olmalarına önem verir ve bunu mümkün kılar. Sağlık
                            sorunlarında fonksiyonel bakış açısı ile daha az
                            invaziv tedavi seçeneklerini deneyimlersiniz.
                            Fonksiyonel tıp yaklaşımı, çocuğunuz için tedavi
                            seçeneklerini genişletme potansiyeline ve kronik
                            koşullara yeni yaklaşımlarda bulunmamızı sağlar.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Card className="mb-4" style={{ height: "350px" }}>
                        <Card.Body>
                          <Card.Title>FONKSİYONEL TIP YAKLAŞIMI</Card.Title>
                          <Card.Text>
                            Fonksiyonel tıp hekimimiz, çocuğunuzun semptomlarını
                            hafifletmesi için kişiselleştirilmiş bir plan
                            formüle etmek üzere ayrıntılı bir özgeçmiş bilgisi,
                            çevresel faktörler ve mevcut beslenme
                            alışkanlıkların detayını sizden öğrenir. Yetişkin
                            olarak yaşadığımız birçok hastalık, birçok etkene
                            çocukken maruz kalmamızın sonucudur. Bu nedenle,
                            çocuklarımızın sahip olduğu maruziyetleri en aza
                            indirgemede önleyici olabilirsek, gelecekteki
                            hastalıkları ve/veya bozuklukları daha başarılı bir
                            şekilde önleyebiliriz.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Card className="mb-4" style={{ height: "350px" }}>
                        <Card.Body>
                          <Card.Title>YAYGIN ŞİKAYETLER</Card.Title>
                          <ul>
                            <li>Karın ağrısı</li>
                            <li>Allerji Belirtileri</li>
                            <li>İshal/ kabızlık</li>
                            <li>Kilo kaybı/ kilo alma</li>
                            <li>Öfke nöbetleri</li>
                            <li>Davranış sorunları</li>
                            <li>Dikkat eksikliği</li>
                            <li>Otizm spektrum bozukluğu belirtileri</li>
                            <li>Toksik maruziyetlere bağlı yakınmalar</li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>SİZİ NELER BEKLİYOR?</Card.Title>
                          <ul>
                            <li>
                              <strong>DİNLİYORUZ: </strong> <br />
                              Biliyoruz ki çocuğunuzu herkesten daha iyi
                              tanıyorsunuz. Bilgilerinize ve kararlarınıza saygı
                              duyuyoruz.
                            </li>
                            <br />
                            <li>
                              <strong>
                                ÇOCUĞUNUZU BİR BÜTÜN OLARAK GÖRÜYORUZ:
                              </strong>
                              <br />
                              Fonksiyonel Tıp, yaşları ne olursa olsun her
                              hastayı bütünsel inceler ve genel fayda
                              sağlayabilir.
                            </li>
                            <br />
                            <li>
                              <strong>PROAKTİF OLMAYI HEDEFLİYORUZ:</strong>
                              <br />
                              Birçok çocukluk ve ergenlik dönemi sağlık
                              sorunları, beslenme ve yaşam tarzı seçimlerine
                              doğru odaklanarak hastalıklar gelişmeden
                              önlenebilir.
                            </li>
                            <br />
                            <li>
                              <strong>DOĞAL ÜRÜNLER ÖNERİYORUZ: </strong>
                              <br />
                              Kaliteli, doğal seçenekleri tercih etmenin
                              çocuğunuzun sağlığını optimize etmek için bir
                              öncelik olduğunu biliyoruz.
                            </li>
                            <br />
                            <li>
                              <strong>KARARLARINIZA SAYGI DUYUYORUZ: </strong>
                              <br />
                              Çocuğunuz için en iyi olanı seçmeniz konusunda
                              sizinle birlikte yol alıyoruz.
                            </li>
                            <br />
                            <li>
                              <strong>İLACINIZ, GIDANIZ: </strong> <br />
                              Zindeliğe odaklanan bir temel oluşturmak için
                              ailenizi sağlıklı gıda seçimleri konusunda
                              eğitiyoruz.
                            </li>
                          </ul>
                          <br />
                          <Card.Text>
                            <b>YAKLAŞIMIMIZ:</b> <br />
                            Sağlık sorunlarını izole görmek yerine çocuğunuza
                            bir bütün olarak bakıyoruz.Bu yaklaşım, daha önce
                            keşfedilmemiş altta yatan sorunları da belirlememize
                            yardımcı olur.Sağlıklı ve dengeli yaşaması için
                            çocuğunuzu etkileyen faktörler arasında uyku,
                            beslenme, yaşam tarzı ve çevresel faktörler yer
                            alır.Bu alanların herbirini ele alarak ,
                            endişelerinizi konuşarak , sizin ve çocuğunuzun
                            karşılaşabileceği yaşa bağlı ve gelişimsel
                            değişikliklere hazırlıklı olmanız için kapsamlı bir
                            planı oluşturabiliriz.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>UYKU</Card.Title>
                          <Card.Text>
                            Kronik uyku yoksunluğu; kilo alımı/obezite, diyabet,
                            kalp hastalığı, zayıf bağışıklık sistemi, zayıf
                            büyüme, anksiyete/depresyon, zayıf hafıza ve DEHB
                            semptomları dahil olmak üzere zayıf fiziksel ve
                            zihinsel sorunlara yol açabilir. Çocuklar uyurken
                            hem gelişirler hem vücut ve beyin açısından günün
                            toksik yükünü "temizler" ve detoksifiye ederler.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>BESLENME</Card.Title>
                          <Card.Text>
                            Çocuklar büyüme ve gelişme çağında olduğu için ,
                            yetişkinlere bahsettiğimiz faydalı besinler çocuklar
                            için tartışmasız daha da önem teşkil etmektedir.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>YAŞAM TARZI</Card.Title>
                          <Card.Text>
                            Bütüncül çocuk sağlığına yaklaşımımız, çocuğunuzun
                            yaşam tarzının kapsamlı bir değerlendirmesini
                            içerir. Beslenme ve zindelikten, duygusal ve
                            zihinsel sağlığa kadar noktaları birleştirmek için
                            fonksiyonel tıp bakış açısı ile danışanlarımızı
                            değerlendiriyoruz.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>ÇEVRESEL FAKTÖRLER</Card.Title>
                          <Card.Text>
                            Çocuğunuza bir bütün olarak bakarken, sadece bedeni
                            değil, çevresel faktörleri de göz önünde
                            bulunduruyoruz. Bunlar ; maruziyetler, su kalitesi,
                            kontaminasyonlar , toksik ajanlar vb. olabilir.
                            Ayrıca duygusal durumlarını da keşfedebiliriz. Bu,
                            yaşam durumunu, sosyal çevreyi, arkadaşlıkları vb.
                            içerebilir. Stres, besinler ve uykunun kalitesi gibi
                            faktörlerin hepsi vücutlarının nasıl geliştiği
                            konusunda rol oynar
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>HER ÇOCUK EŞSİZDİR</Card.Title>
                          <Card.Text>
                            Bütüncül çocuk sağlığı programı ‘’benim çocuğum
                            tek’’ yaklaşımını içerir. Çocuğunuz ve onun
                            benzersiz ihtiyaçları için kişiselleştirilmiş ve
                            özgün bir plan oluşturuyoruz. İki çocuk aynı
                            olmadığı gibi iki plan da aynı değildir. Tedavi
                            önerilerimiz, kanıta dayalı ve en uygun seçeneğin
                            yanı sıra her bir çocuk ve aile için en uygun tedavi
                            yöntemlerini de içerecektir. Bunlar arasında
                            beslenme değişiklikleri, besin takviyeleri,
                            farmasötik destekler, yaşam tarzı yönetimi,
                            aromaterapötik ve bitkisel takviyeler yer alabilir.
                            Bir ebeveyn olarak, çocuğunuz için neyin en iyi
                            olduğuna sürekli karar vermeye çalışıyorsunuz. Bu
                            yolculukta size yardımcı olmak için buradayız.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>HER ÇOCUK EŞSİZDİR</Card.Title>
                          <Card.Text>
                            Bütüncül çocuk sağlığı programı "benim çocuğum tek"
                            yaklaşımını içerir. Çocuğunuz ve onun benzersiz
                            ihtiyaçları için kişiselleştirilmiş ve özgün bir
                            plan oluşturuyoruz.
                            <br /> İki çocuk aynı olmadığı gibi iki plan da aynı
                            değildir. Tedavi önerilerimiz , kanıta dayalı ve en
                            uygun seçeneğin yanı sıra her bir çocuk ve aile için
                            en uygun tedavi yöntemlerini de içerecektir. Bunlar
                            arasında beslenme değişiklikleri, besin takviyeleri,
                            farmasötik destekler, yaşam tarzı yönetimi,
                            aromaterapötik ve bitkisel takviyeler yer alabilir.
                            <br />
                            Bir ebeveyn olarak, çocuğunuz için neyin en iyi
                            olduğuna sürekli karar vermeye çalışıyorsunuz. Bu
                            yolculukta size yardımcı olmak için buradayız.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>Sık Sorulan Sorular</Card.Title>
                          <Card.Text>
                            Muhtemelen, sormak istediğiniz soruların aynısı daha
                            önce sorulmuş olabilir:{" "}
                            <a href="/sss">tıklayınız.</a>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Footeralt />
      </div>
    </div>
  );
}

export default KadınVeCocuk;
