import React from "react";
import Modal from "react-modal";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { FaTimes } from "react-icons/fa";
import { pdfjs } from "react-pdf";

const MyPDFViewer = ({ pdfFile, isOpen, onClose }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)"
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "80%",
          padding: 0,
          overflow: "hidden"
        }
      }}
    >
      <div style={{ backgroundColor: "#f0f0f0", borderBottom: "1px solid #ccc", position: "sticky", top: 0, zIndex: 2 }}>
        <button
          onClick={onClose}
          style={{
            float: "right",
            background: "none",
            border: "none",
            cursor: "pointer"
          }}
        >
          <FaTimes size={20} />
        </button>
      </div>
      <div style={{ padding: "20px", overflowY: "auto", height: "100%" }}>
      <Worker workerUrl={`//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
        <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
      </div>
    </Modal>
  );
};

export default MyPDFViewer;
