import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../images/Logo.png";

const TopCard = (props) => {

  const phoneNumber = "+905434017092"; 

  const openWhatsApp = () => {
      const message = "Merhaba, size ulaşmak istiyorum."; 
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      window.open(whatsappURL, '_blank');
  };

  const handleLogoClick = () => {
    window.location.href = "/"; 
  };

  const handleMailClick = () => {
    window.location.href = "mailto:info@drbengusuozkan.com"; 
  };


  return (
    <footer
      className={props.className}
      style={{ backgroundColor: "#ECECEC", color: "#EBC42A", padding: "20px 0" }}
    >
      <Container>
        <Row>
          <Col xs={12} md={4} className="logo-col">
            <img
              src={Logo}
              alt="Logo"
              style={{ paddingLeft:"10px", width: "45%", height: "110px" }}
              onClick={handleLogoClick}
            />
          </Col>
          <Col xs={6} md={3} className="info-col">
            <div style={{ margin: "0 auto", paddingTop: '20px' }}>
              <p>
                <FontAwesomeIcon icon={faClock} color="grey" size="lg" />{" "}
                <span style={{ fontWeight: "800", width: "150px", display: "inline-block" , color:"#d7b27f"}}>
                  Pazartesi-Cuma :
                </span>{" "}
                <span style={{ fontWeight: "800", color:"#d7b27f" }}>09:00 - 18:00</span>
              </p>
              <p>
                <FontAwesomeIcon icon={faClock} color="grey" size="lg" />{" "}
                <span style={{ fontWeight: "800", width: "150px", display: "inline-block" , color:"#d7b27f"}}>
                  Cumartesi-Pazar:
                </span>{" "}
                <span style={{ fontWeight: "800", color:"#d7b27f"}}>10:00 - 20:00</span>
              </p>
            </div>
          </Col>
          <Col xs={6} md={2} className="info-col">
            <p  onClick={openWhatsApp} style={{ paddingTop: "40px", fontWeight: "800", color:"#d7b27f" }}>
              <FontAwesomeIcon icon={faPhone} color="grey" size="lg" /> {" "}+90 543 401 70 92
            </p>
            </Col>
            <Col xs={6} md={3} className="info-col">
            <p style={{ paddingTop: "40px", fontWeight: "800", color:"#d7b27f" }} onClick={handleMailClick}>
              <FontAwesomeIcon icon={faEnvelope} color="grey" size="lg" />{" "} info@drbengusuozkan.com
            </p>
          </Col>
        </Row>
      </Container>
      <style jsx>{`
        @media (max-width: 767px) {
          /* Küçük ekranlarda (xs), sadece logo görünecek */
          .info-col {
            display: none;
          }
          .logo-col {
            text-align: center;
          }
        }
      `}</style>
    </footer>
  );
};

export default TopCard;
