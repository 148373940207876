import {Route, Routes} from 'react-router-dom';
import HomePage from "./pages/HomePage.jsx"
import FonksiyonelTıp from "./pages/FonksiyonelTıp.jsx";
import Sss from './pages/Sss.jsx';
import Blog from './pages/Blog.jsx';
import HeartMath from './pages/HeartMath.jsx';
import Intrevanoz from './pages/Intrevanoz.jsx';
import RejaneratifTıp from './pages/RejaneratifTıp.jsx';
import IDCheckIUp from './pages/IDCheckIUp.jsx';
import KadınVeCocuk from './pages/KadınVeCocuk.jsx';
import Prepoksiyonel from './pages/Prepoksiyonel.jsx';
import CiltVeOtesi from './pages/CiltVeOtesi.jsx';

function App() {
    return (
      <div >
        <Routes>
            <Route exact path='/' element={<HomePage/>}></Route>
            <Route exact path='/fonksiyonelTip' element={<FonksiyonelTıp/>}></Route>
            <Route exact path='/heartMath' element={<HeartMath/>}></Route>
            <Route exact path='/intravenoz' element={<Intrevanoz/>}></Route>
            <Route exact path='/rejaneratif' element={<RejaneratifTıp/>}></Route>
            <Route exact path='/kadinvecocuk' element={<KadınVeCocuk/>}></Route>
            <Route exact path='/prekonsepsiyonel' element={<Prepoksiyonel/>}></Route>
            <Route exact path='/butunselGuzellik' element={<CiltVeOtesi/>}></Route>
            <Route exact path='/idCheckUp' element={<IDCheckIUp/>}></Route>
            <Route exact path='/sss' element={<Sss/>}></Route>
            <Route exact path='/blog' element={<Blog/>}></Route>
        </Routes>
      </div>
    );
  }
  
  export default App;