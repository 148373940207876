import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import "@react-pdf-viewer/core/lib/styles/index.css";
import TopCard from "../components/topCard/TopCard";
import CustomNavbar from "../components/navbar/Navbar";
import Footeralt from "../components/footer2/Footeralt";
import "./Pages.css";

function IDCheckIUp() {
  const [topCardHeight, setTopCardHeight] = useState(0);

  useEffect(() => {
    const topCardElement = document.querySelector(".top-card");
    if (topCardElement) {
      const topCardRect = topCardElement.getBoundingClientRect();
      setTopCardHeight(topCardRect.height);
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position = "relative";
        navbarElement.style.backgroundColor = "grey";
      }
    }

    function handleScroll() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position =
          scrollY > topCardHeight ? "fixed" : "relative";
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topCardHeight]);

  return (
    <div>
      <TopCard className="top-card" />
      <div className="navbar-fixed">
        <CustomNavbar />
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-3">
          <Row>
            <Col md={12}>
              <Card className="pt-3 mb-4">
                <Card.Title style={{ textAlign: "center" }}>
                  Integratif ve Fonksiyonel Medikal (IFM) ID-Check Up Programı
                </Card.Title>
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Text>
                            kontrollerinin amacı, hastalıkları erken müdahalenin
                            etkili olabileceği aşamada tespit edebilmektir.
                            <br />
                            Önleyici Sağlık Yönetimi ile sağlığınızı ve günlük
                            performansınızı optimize etmenize yardımcı olmak
                            hedeflenir. Bu amaçla İleri Diyagnostik Check Up
                            (ID-Check Up) programına dahil edilirsiniz ve
                            yolculuğunuz başlar. Bu yolculukta fonksiyonel tıp
                            hekiminin denetiminde her danışanın en iyi sağlık
                            durumuna doğru ilerlemesi için çalışılır.
                            <br />
                            Fonksiyonel tıp hekimi tarafından alınan ayrıntılı
                            öykünüz ve fizik muayeneniz sonrası karar verilen
                            geleneksel tıbbi tetkiklerin yanında, kapsamlı
                            Integratif ve Fonksiyonel Medikal (IFM) testleri ve
                            görüntülemeyi içeren ID- Check Up uygulamasının
                            amacı sağlığınızı ve günlük performansınızı optimize
                            etmenize yardımcı olmaktır.Bu programa dahil
                            olduktan sonra sizin için gerekli kontrol zamanları
                            tespit edilir. Ve bir nevi sağlık aboneliğiniz
                            başlamış olur.
                            <br /> Sadece sağlıklı olmanız durumunda değil,
                            mevcut bir tanınız olsa bile takipte olmanız; hem
                            mevcut tedavinizin devamlılığı ve etkinliği , hem de
                            olası komplikasyonların önlenmesi açısından
                            önemlidir.
                            <br /> Bu program hem yetişkinleri, hem de pediatrik
                            yaş grubunu kapsamaktadır.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>Obezite & İdeal Kilo Yönetimi</Card.Title>
                          <Card.Text>
                            Günümüzde obezite dünyada giderek artan bir tıbbi
                            sorundur. Obezitenin tedavi edilmesi ile kalp
                            hastalığı, diyabet, bir çok kanser türü, astım,
                            obstruktif uyku apnesi, kronik kas-iskelet sistemi
                            sorunları dahil olmak üzere çeşitli tıbbi sorunlara
                            yakalanma riskinizi ortadan kaldırmış olursunuz. Bu
                            amaçla öncelikle Biyoelektriksel Empedans cihazı ile
                            vücut analiziniz yapılır, ilk görüşmede biyolojik
                            sisteminizdeki ön görülen temel sorun tespiti için
                            uygulanacak Fonksiyonel Testlerinizin sonucuna göre
                            planınız oluşturulur.Düzenli kontrolleriniz
                            sonucunda sizden gelen geribildirimlere dayanarak
                            sürekli güncellenen planınız ile sizin için uygun
                            olan sağlıklı kilo hedefine ulaşmanız sağlanır.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>Fonksiyonel Testler:</Card.Title>
                          <Card.Text>
                            Geleneksel batı tıbbı uygulamalarının en iyilerini,
                            yenilikçi Integratif ve (IFM) testleriyle
                            birleştiriyoruz. Her hastanın farklı olduğunu ve
                            kişiselleştirilmiş bir tedavi planı gerektirdiğini
                            biliyoruz. Takviyeler, bitkisel destekler, reçeteli
                            ilaçlar, terapötik diyetler, detoks programları veya
                            stres yönetimi teknikleri olsun, sizin için neyin
                            işe yaradığını tespit ederek hedefe ulaşmanızda hep
                            yanınızdayız.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Footeralt className="footeralt" />
      </div>
    </div>
  );
}

export default IDCheckIUp;
