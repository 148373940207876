import React, { useEffect, useState, useRef } from "react";
import CustomNavbar from "../components/navbar/Navbar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Pages.css";
import Footeralt from "../components/footer2/Footeralt";
import TopCard from "../components/topCard/TopCard";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

function Prepoksiyonel() {
  const [navbarFixed, setNavbarFixed] = useState(false);
  const topCardRef = useRef(null);
  const phoneNumber = "+905434017092";

  const openWhatsApp = () => {
    const message = "Merhaba, size ulaşmak istiyorum.";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  useEffect(() => {
    function handleScroll() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const topCardElement = topCardRef.current;
      if (topCardElement) {
        const topCardRect = topCardElement.getBoundingClientRect();
        const topCardHeight = topCardRect.height;
        setNavbarFixed(scrollY > topCardHeight);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="home-page-container">
        <TopCard ref={topCardRef} className="top-card" />
        <div className={navbarFixed ? "navbar-fixed" : ""}>
          <CustomNavbar />
        </div>
        <div style={{ backgroundColor: "#f2f2f2" }}>
          <Container className="pt-3">
            <Row>
              <Col md={12}>
                <Card className="pt-3 mb-4">
                  <Card.Title style={{ textAlign: "center" }}>
                    Prekonsepsiyonel Sağlık Bakımı
                  </Card.Title>
                  <Card.Body>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Gebe Kalma Şansınızı Artırmak İçin Yardım mı
                            Arıyorsunuz? Fonksiyonel Tıbbın Size Nasıl Yardımcı
                            Olabileceğini Öğrenin.
                          </Card.Title>
                          <Card.Text>
                            Prekonsepsiyonel sağlık bakımı, anne ve babanın risk
                            oluşturan fiziksel, tıbbi ve psikososyal durumlarını
                            gebelikten önce saptayarak, uygun şekilde çözümleme
                            veya yönlendirmeye dayanan bir hizmettir. <br />
                            Prekonsepsiyonel sağlık bakımı, hamilelikten önce
                            her iki partnerin sağlığını en üst düzeye çıkarmakla
                            ilgilidir. Amaç, Fonksiyonel tıp Yaklaşımı ile
                            doğurganlığı optimize etmek ve sağlıklı bir
                            hamilelik sağlamaktır.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Fonksiyonel Tıp Yaklaşımı ile Bu Nasıl Mümkün Olur?
                          </Card.Title>
                          <Card.Text>
                            Hamilelik yolculuğunuzda gerekli olacak mümkün
                            olduğunca en iyi sağlığa kavuşmanız için önceden var
                            olan sağlık koşullarını destekler. Doğurganlığınızı
                            veya hamileliğinizi etkileyebilecek herhangi bir
                            sorunu belirlemeyi hedefler. Bu, polikistik over
                            sendromu, düşük tiroid fonksiyonu, anemi ve yüksek
                            tansiyon gibi durumları kontrol etmek için
                            fonksiyonel tıp hekimi ile birlikte çalışmayı
                            içerir. Bebek için rahimde en iyi ortamı sağlamak
                            üzere gerekli önlemleri almanızı hedefler. Bu, yaşam
                            tarzı değişikliklerinin yanı sıra vücudunuzda
                            depolanan toksinleri temizleye yardımcı olacak özel
                            bir protokolü içerir. Hamilelik sırasında toksinleri
                            harekete geçirmek istemediğimiz için bunun gebe
                            kalmaya çalışmadan önce yapılması önemlidir.
                          </Card.Text>
                          <Card.Text>
                            Sağlıklı bir hamileliği desteklemek için gereken tüm
                            vitamin ve mineraller açısından zengin, sağlıklı ve
                            dengeli bir diyetle besin durumunu en üst düzeye
                            çıkarmak hedeflenir. Sindirim sağlığınız optimal
                            olmalıdır. Bu, besin emilimini en üst düzeye
                            çıkarmak ve aynı zamanda mümkün olan en sağlıklı
                            mikrofloraya sahip olmanızı sağlamak içindir.
                            Mikrofloranız doğum sırasında ve emzirme yoluyla
                            bebeğin mikroflorasını etkilediği için bu önemlidir.
                            Yararlı mikroflora, bebeğinizin bağışıklığını olumlu
                            yönde etkilemenin önemli bir yoludur. Fonksiyonel
                            tıp yaklaşımı ile stres ve uyku düzeniniz üzerinde
                            de çalışmaktayız. Stres hormonları ve düzensiz uyku
                            alışkanlıkları hamile kalmayı zorlaştırabilir.
                            Etkili stres yönetimi ve optimum uyku da sizi
                            yenidoğana bakmanın zorluklarına daha iyi
                            hazırlayacaktır!
                          </Card.Text>
                          <Card.Text>
                            Erkekler için spermin olgunlaşması iki buçuk ila üç
                            ay sürer. Burada ana odak noktası, sağlıklı bir
                            bebek sahibi olma şansını artırmak için sperm
                            kalitesini optimize etmektir. Sperm kalitesi birçok
                            diyet ve yaşam tarzı faktöründen etkilenebilir.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Erkekler İçin Prekonsepsiyonel Bakımında Fonksiyonel
                            Tıp Yaklaşımı
                          </Card.Title>
                          <Card.Text>
                            Detaylı özgeçmiş bilgileriniz sonucunda gerekli
                            fonksiyonel tıp yaklaşımı ile gerekli testleriniz
                            belirlenir. Tam bir beslenme değerlendirmesi
                            yapılır. Daha sonra besin seviyelerini artırmak,
                            sperm koruyucu antioksidanların alımını en üst
                            düzeye çıkarmak ve sperm üretimini azalttığı ve
                            üreme sağlığını olumsuz etkilediği gösterilen
                            şeylere maruz kalmayı en aza indirmek için
                            kişiselleştirilmiş bir plan oluşturulur. Stres ve
                            uyku desteği değerlendirilir.Araştırmalar, stresin
                            sperm sağlığını azalttığını göstermektedir.Bu
                            nedenle stres yönetimi, babalar ve adayları için
                            aynı derecede önemlidir.
                          </Card.Text>
                          <Card.Text>
                            Herhangi bir fonksiyonel tıp konsültasyonunda olduğu
                            gibi, her iki partnerin de fonksiyonel medikal
                            matriksini oluşturmayı hedefliyoruz. Daha sonra
                            doğurganlığı ve hamileliği etkileyebilecek altta
                            yatan dengesizlikleri kontrol etmek için fonksiyonel
                            tıp hekimimiz tarafından özel testler
                            önerilmektedir. Sürecin her aşaması
                            kişiselleştirilmiştir. İlgili test sonuçlarını
                            aldıktan sonra, sağlığınızı ve doğurganlığınızı
                            optimize etmek için her partnere özgü adımları
                            içeren -neye ihtiyacınız olduğunu, nelerin
                            hayatınızdan çıkarılması gerektiği bilgisinin dahil
                            olduğu bir planı uyguluyoruz.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Text style={{ textAlign: "center" }}>
                            Detaylı bilgi için randevu alabilirsiniz. Randevu
                            almak için
                            <Button
                              variant="link"
                              onClick={openWhatsApp}
                              style={{
                                paddingLeft: 5,
                                padding: 0,
                                margin: 0,
                                textDecoration: "underline",
                              }}
                            >
                              tıklayınız.
                            </Button>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <div style={{ backgroundColor: "#f2f2f2" }}>
          <Footeralt className="footeralt" />
        </div>
      </div>
    </div>
  );
}

export default Prepoksiyonel;
