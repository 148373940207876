import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUserFriends, faChartLine, faComments, faCogs } from '@fortawesome/free-solid-svg-icons';
import './Cards.css'; 

const Features = () => {
    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col md={4} className="text-center mb-4">
                    <div className="feature-card">
                        <FontAwesomeIcon icon={faClock} className="feature-icon" />
                        <h3>ZAMAN</h3>
                        <p>Daha fazla zamanı hak ediyorsunuz. Hastalarımıza özel zaman ayırıyoruz.</p>
                    </div>
                </Col>
                <Col md={4} className="text-center mb-4">
                    <div className="feature-card">
                        <FontAwesomeIcon icon={faUserFriends} className="feature-icon" />
                        <h3>İŞBİRLİĞİ</h3>
                        <p>Size özel tedavi planı ve hedefleri geliştirme amaçlı sizinle birlikte çalışıyoruz.</p>
                    </div>
                </Col>
                <Col md={4} className="text-center mb-4">
                    <div className="feature-card">
                        <FontAwesomeIcon icon={faCogs} className="feature-icon" />
                        <h3>BİREYSELLİK</h3>
                        <p>İhtiyaçlarınıza özel ve yaşam tarzınıza uyan bireysel bir plan hazırlıyoruz.</p>
                    </div>
                </Col>
                <Col md={4} className="text-center mb-4">
                    <div className="feature-card">
                        <FontAwesomeIcon icon={faComments} className="feature-icon" />
                        <h3>İLETİŞİM</h3>
                        <p>Rehberlik ve destek sağlama konusunda iletişim halindeyiz.</p>
                    </div>
                </Col>
                <Col md={4} className="text-center mb-4">
                    <div className="feature-card">
                        <FontAwesomeIcon icon={faChartLine} className="feature-icon" />
                        <h3>İLERİ DÜZEY ANALİZ</h3>
                        <p>Temel nedeni belirlemek için ileri düzey laboratuvar testleri kullanıyoruz.</p>
                    </div>
                </Col>
                <Col md={4} className="text-center mb-4">
                    <div className="feature-card">
                        <FontAwesomeIcon icon={faCogs} className="feature-icon" />
                        <h3>ÇÖZÜM</h3>
                        <p>Fonksiyonel ve bütüncül yaklaşımla optimum koşullara taşımak.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Features;
