import React, { useEffect, useState } from "react";
import CustomNavbar from "../components/navbar/Navbar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Pages.css";
import Footeralt from "../components/footer2/Footeralt";
import TopCard from "../components/topCard/TopCard";
import { Accordion, Container } from "react-bootstrap";

function Blog() {
  const [topCardHeight, setTopCardHeight] = useState(0);

  useEffect(() => {
    const topCardElement = document.querySelector(".top-card");
    if (topCardElement) {
      const topCardRect = topCardElement.getBoundingClientRect();
      setTopCardHeight(topCardRect.height);
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position = "relative";
        navbarElement.style.backgroundColor = "grey";
      }
    }

    function handleScroll() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position =
          scrollY > topCardHeight ? "fixed" : "relative";
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topCardHeight]);

  return (
    <div>
      <TopCard className="top-card" />
      <div className="navbar-fixed">
        <CustomNavbar />
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-5 pb-3">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Herkes için Sağlıklı Olma Rehberi
              </Accordion.Header>
              <Accordion.Body>
                Sizi mutsuz eden sağlık sorunlarınız var ve bunlar çok uzun
                zamandır sürdüğü için artık yaşamınızın bir parçası olduğunu mu
                düşünüyorsunuz? Örneğin hep başınız ağrır mı?Ya da yemeklerden
                sonra hemen karnınız şişer ya da uykunuz mu gelir? Hatırlamada
                güçlük mü yaşıyorsunuz? Ya da cildiniz eskisi gibi parlak değil
                veya istediğiniz kiloya ulaşamıyor musunuz? Bu ve daha birçok
                semptom, vücudunuzda bir dengesizlik olduğunun işaretidir. Bu
                kılavuz, sağlığınızı ve zindeliğinizi kontrol altına almak için
                yeni bir yaklaşım olan "Fonksiyonel Tıp" hakkındadır.
                Fonksiyonel tıp, sağlık sorunlarınızı bir hastalığa dönüşmeden
                önce ele alarak iyi hissetmenize yardımcı olabilir. Eğer
                hastalık haline gelirse, Fonksiyonel Tıp yaklaşımı bu durumu
                tersine çevirmenize, ortadan kaldırmanıza veya daha iyi
                yönetmenize yardımcı olabilir. Sağlığınızı korumaya ya da
                onarmaya hazır mısınız?
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                Fonksiyonel Tıp Temel İlkeleri
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    Her kişinin biyokimyasal yapısındaki potansiyel
                    farklılıkların daha derin bir şekilde anlaşılması
                  </li>
                  <li>Hem iç, hem dış faktörlerin incelenmesi</li>
                  <li>
                    İnsanın yaşam uzunluğuna değil, yaşam kalitesine odaklanmayı
                  </li>
                  <li>
                    Hasta ve hekim ilişkisini, tedavi edici bir işbirliği olarak
                    görmeyi içerir
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>Fonksiyonel Tıp Nedir?</Accordion.Header>
              <Accordion.Body>
                Fonksiyonel tıp, semptomları bastırmak yerine altta yatan
                nedenleri ele alarak hastalıkları önlemeye ve yönetmeye
                odaklanan bir tedavi yaklaşımıdır.
                <br /> Fonksiyonel tıp felsefesi; hekiminizin kim olduğunuzu ve
                nasıl yaşadığınızı anlamasını gerektirir, böylece tedavileriniz
                size göre kişiselleştirilir ve sadece semptomları değil, sağlık
                sorunlarınızın temel nedenini ele alır.
                <br /> Fonksiyonel Tıp yaklaşımında amaç semptomu değil kişiyi
                tedavi edebilmektir. Sizi birkaç dakika dinleyip semptomlarınızı
                listeledikten sonra bir hastalık teşhisi koyan ve semptomu
                gizleyen bir hapın yazıldığı bir yaklaşım yerine fonksiyonel
                tıp; ilk etapta vücudunuzun tüm sistemlerine bakar.
                <br /> Fonksiyonel değerlendirme ile temel sorunu öğrendikten
                sonra, organlarınızı ve sistemlerinizi yeniden dengelemek için
                fonksiyonel tıp hekimi ile işbirliği içinde çalışarak, böylece
                vücudunuz kendi kendine iyileşme yoluna girer ve semptomlar
                kaybolur. Kökteki bir sorunu düzeltmekle başlayan sağlıklı bir
                "domino etkisi" yaratır. <br /> Geleneksel tıp, genelde
                semptomları baskılamaya odaklanır. Bunun için de farmasötik
                ilaçlar ile tıbbi veya cerrahi prosedürlere başvurur. Buna
                karşılık, fonksiyonel tıp, semptomlarınızı oluşturan
                dengesizliğin temel nedenini ele almaya odaklanır. Bunu yapmak
                için, fonksiyonel tıp hekimleri , yaşam tarzı değişiklikleri,
                farkındalık terapisi, dengeli beslenme, bitkisel destekler ve
                gerektiğinde farmasötikler dahil birçok araç ve yöntemi
                kullanır.Fonksiyonel tıp, beslenme, yaşam tarzı, egzersiz, uyku,
                stres yönetimi, çevre koşulları, manevi inançlar ,duygusal ve
                zihinsel faktörler gibi sağlığınızı etkileyen birçok şeyi
                dikkate alır.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Fonksiyonel Tıp Bana Nasıl Yardımcı Olabilir?
              </Accordion.Header>
              <Accordion.Body>
                Olağandışı saç dökülmesi, karın şişkinliği, halsizlik ve regl
                öncesi sendrom (PMS) gibi "günlük" sağlık sorunlarından kanser,
                otoimmün hastalıklar, hipertansiyon ve kardiyovasküler hastalık
                vb. sağlık sorunları dahil fonksiyonel tıp, sağlık sorunlarını
                önlemenize veya tersine çevirmenize, kronik tıbbi durumları daha
                iyi yönetmenize yardımcı olabilir.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Fonksiyonel Tıp Hekimi ile Geçirdiğiniz Süre
              </Accordion.Header>
              <Accordion.Body>
                Fonksiyonel tıp hekimi ile geçirdiğiniz süre boyunca,
                semptomlarınız, yaşam tarzınız ve geçmişiniz hakkındaki
                bilgileri gözden geçireceksiniz. Bu değerlendirme vücudunuzdaki
                her sistemi içerir, böylece fonksiyonel tıp hekimi, baştan
                tırnağa ve en önemlisi dengesizliklerin nerede olduğu ile ilgili
                önemli ipuçları elde etmiş olur.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>En Sağlıklı Sen Olmak</Accordion.Header>
              <Accordion.Body>
                Fonksiyonel tıp doktorunu ilk ziyaretinizde, size ait bir zaman
                çizelgesi çıkarılır. Bu sayede sağlığınızın tam bir resmini
                geliştirirken ve mevcut sağlık durumunuza işaret eden temel
                sorunları ararken mümkün olduğunca hiçbir şeyin gözden
                kaçırılmamasını sağlar. Bu, sıklıkla gözden kaçabilecek sağlık
                sorunlarının gerçek nedenini genellikle tespit eden kapsamlı bir
                süreçtir.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Fonksiyonel Tıp Taraması ve Geleneksel Tıbbi Tarama Arasındaki
                Fark
              </Accordion.Header>
              <Accordion.Body>
                Geleneksel tıbbi tarama, sağlık sorunlarını teşhis etmek için
                standart semptomları arar. Ancak bu tarama, sağlığınızı
                etkileyebilecek psikolojik, duygusal, ruhsal, yaşam tarzı ve
                tetikleyici olayları veya geçmiş yaşam faktörlerini hesaba
                katmaz. Mevcut durumunuza önemli katkıda bulunanlara değinmek
                için yeterli eğitim veya zaman olmayabilir, bu nedenle bazı
                geleneksel doktorlar bu alanlar hakkında soru sormamayı tercih
                edebilirler. Fonksiyonel tıp hekiminiz tarafından bir şablon
                kılavuzunda yönlendirilen görüşmenin sonucu, sizin ve
                hekiminizin geleneksel tıbbın sağlığa bu açılardan bakmadığı
                için kolayca gözden kaçırabileceği dengesizlik ve sağlık
                sorunlarını keşfedebilmesine olanak sağlar.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Önleme Anahtardır</Accordion.Header>
              <Accordion.Body>
                Fonksiyonel tıp, tetikleyicileri ve öncülleri tespit etmek için
                sağlık ve yaşam tarzı geçmişinize bakar (yakın veya uzun zaman
                önce olanlar, bugünkü sağlığınızda etkili bir faktör olabilir).
                Bu önemlidir çünkü fonksiyonel tıbbın amaçlarından biri
                önlemedir. Her insan benzersizdir. Kimileri çeşitli sağlık
                sorunları için genetik yatkınlığa sahip olabilir. Bir
                tetikleyici, bir kişinin genetik olarak yatkın olduğu bir
                hastalığı ortaya çıkarmasına neden olabilirken, aynı yatkınlığa
                sahip, bu tetikleyiciyle karşılaşmayan başka birinin hastalığı
                asla ortaya çıkmayabilir. Geleneksel tıbbi tarama ve fonksiyonel
                tıp taraması arasındaki hastalığı önlemeye yönelik bakış
                açısındaki bu büyük fark, şimdi ve gelecekte, sağlığınızda ve
                genel yaşam kalitenizde önemli bir fark yaratabilir. Fonksiyonel
                tıp hekimi, bu kapsamlı değerlendirmeye dayanarak gelişen sağlık
                sorununu öngörebilir ve bunu önlemek için gerekli değişiklikleri
                yapmanıza yardımcı olabilir. En önemlisi, bir hasta olarak,
                kendi sağlık kalıplarınızı veya zayıflıklarınızı fark eder ve
                tanırsınız ve hastalığa düşme sürecini durdurmak veya tersine
                çevirmek için gerekli değişiklikleri yapabilirsiniz. Bu kendi
                sağlığınız üzerinde kontrol sahibi olmanızı sağlar.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Hazırlık Anketi</Accordion.Header>
              <Accordion.Body>
                Fonksiyonel tıp için bu kılavuzun yaklaşık yarısındasınız.
                Sağlığınızı değerlendirmenin ve bakımını yapmanın daha iyi bir
                yoluna geçmeye hazır mısınız? Öğrenmek için hazırlık anketini
                alın!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                Yaygın Olarak Gözden Kaçan Hastalıklar
              </Accordion.Header>
              <Accordion.Body>
                Bu videoları izleyerek birkaçı hakkında daha fazla bilgi
                edinebilirsiniz:
                <ul style={{ paddingLeft: "20px", marginTop: "10px" }}>
                  {[
                    "SIBO",
                    "CANDIDA",
                    "PKOS",
                    "DYSBIOSIS",
                    "UYKUSUZLUK",
                    "TROID BOZUKLUĞU",
                    "OTİZM",
                    "MENOPOZ",
                    "ANDROPOZ",
                  ].map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                Daha İyi Hissetmeye Nasıl Başlanır?
              </Accordion.Header>
              <Accordion.Body>
                Yemek, sağlığın çok güçlü bir bileşenidir! Tüm zamanların en
                önde gelen tıbbi figürlerinden biri olarak kabul edilen eski
                Yunan doktoru Hipokrat, "yiyecekler ilacınız olsun ve ilaçlar
                yemeğiniz olsun" demiş. Fonksiyonel tıp, kaliteli gıda ve su,
                egzersiz ve yaşam tarzı faktörlerini, sağlığın temel taşları
                olarak kabul eder. Hangi semptomları yaşarsanız yaşayın, daha
                iyi hissetmeye başlamanın en hızlı yolları, kaliteli uyku almak,
                besleyici yiyecekler yemek, her gün egzersiz yapmak veya aktif
                olmak, yeterli miktarda temiz su içmek ve stresinizi iyi
                yönetmektir. Fonksiyonel tıp hekimi yaşamınıza dahil edeceğiniz
                değişiklikleri gerçekleştirmenizde size özel kılavuzunuz olarak
                destek olacaktır. Şişkinlikten kansere kadar birçok sağlık
                sorununda, egzersiz ve beslenme vücudunuzun hastalıkları nasıl
                önlediği veya tedavilere nasıl yanıt verdiği konusunda bir fark
                yaratır. Fonksiyonel Tıp yaklaşımı ile bu farkı deneyimlersiniz.
                Bunların hiçbiri muhtemelen sizin bilmediğiniz şeyler değil,
                ancak bu değişiklikleri yapmak için yardıma ihtiyacınız
                olabilir. Veya çözemediğiniz veya henüz hiçbir doktorun
                çözmediği bir semptomla mücadele ediyor olabilirsiniz. Eğer
                öyleyse, sağlığınızı iyileştirmenize yardımcı olmak için bu
                kılavuzda tartışılan araçları, felsefeyi ve yaklaşımı kullanacak
                fonksiyonel tıp hekimimiz ile çalışarak daha iyi hissetme
                yolunda yardım alabilirsiniz.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Footeralt className="footeralt" />
      </div>
    </div>
  );
}

export default Blog;
