import React, { useEffect, useState } from "react";
import CustomNavbar from "../components/navbar/Navbar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Pages.css";
import Footeralt from "../components/footer2/Footeralt";
import TopCard from "../components/topCard/TopCard";
import { Card, Col, Container, Row } from "react-bootstrap";
import rosocea from "../components/images/rosocae.png";
import egzema from "../components/images/egzema.png";
import akne from "../components/images/akne.png";
import prosoro from "../components/images/psoraisis.png";
import ciltsorunlari from "../components/documents/ciltsorunlari.pdf";
import MyPDFViewer from "../components/pdfViewer";

function CiltVeOtesi() {
  const [showViewer, setShowViewer] = useState(false);
  const [topCardHeight, setTopCardHeight] = useState(0);
  const openModal = () => {
    setShowViewer(true);
    document.body.style.overflow = "hidden"; 
  };
  const closeModal = () => {
    setShowViewer(false);
    document.body.style.overflow = "auto"; 
  };

  useEffect(() => {
    const topCardElement = document.querySelector(".top-card");
    if (topCardElement) {
      const topCardRect = topCardElement.getBoundingClientRect();
      setTopCardHeight(topCardRect.height);
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position = "relative";
        navbarElement.style.backgroundColor = "grey";
      }
    }

    function handleScroll() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position =
          scrollY > topCardHeight ? "fixed" : "relative";
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topCardHeight]);

  return (
    <div>
      <TopCard className="top-card" />
      <div className="navbar-fixed">
        <CustomNavbar />
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-3">
          <Row>
            <Col md={12}>
              <Card className="pt-3 mb-4">
                <Card.Title style={{ textAlign: "center" }}>
                  Cilt ve Ötesi
                </Card.Title>
                <Card.Body>
                  <Card.Text style={{ textAlign: "center" }}>
                    Sağlıklı Bir Bağırsak = Sağlıklı Bir Cilt <br />
                    Cildiniz Bağırsak sağlığınızın Yansımasıdır.
                    <br />
                    Akne, Roza, Sedef, Egzema ve çok daha fazlasına veda etmek
                    için buradayız.
                  </Card.Text>
                  <Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Cildinizi İçten Dışa İyileştirmeye Yardımcı Oluyoruz
                          </Card.Title>
                          <Card.Text>
                            <ul>
                              <li>Temiz cilde sahip olmak zor mudur?</li>
                              <li>
                                Cildinizi tedavi etmek için sürekli başka bir
                                krem denemekten bıktınız mı?
                              </li>
                              <li>
                                "Bağırsak-Cilt Bağlantınız" bunlardan herhangi
                                birini veya daha kötüsünü deneyimlemenize sebep
                                olabilir…
                              </li>
                            </ul>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Card className="mb-4" style={{ minHeight: "400px" }}>
                        <Card.Img
                          variant="top"
                          src={akne}
                          style={{
                            width: "100%",
                            maxHeight: "200px",
                            objectFit: "cover",
                          }}
                        />
                        <Card.Body>
                          <Card.Title>Akne</Card.Title>
                          <Card.Text>
                            Sağlıksız cildin nedenleri karmaşıktır ve sindirim
                            sistemi, hormonal, detoksifikasyon ve hatta
                            bağışıklık sistemi dengesizliğini içerebilir.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Card className="mb-4" style={{ minHeight: "400px" }}>
                        <Card.Img
                          variant="top"
                          src={prosoro}
                          style={{
                            width: "100%",
                            maxHeight: "200px",
                            objectFit: "cover",
                          }}
                        />
                        <Card.Body>
                          <Card.Title>Psoraisis</Card.Title>
                          <Card.Text>
                            Deri hücreleri normalden 10 kata kadar daha hızlı
                            çoğalır. Bu, cildin beyaz pullarla kaplı benekli
                            kırmızı lekeler halinde birikmesini sağlar. Her
                            yerde oluşabilirler, ancak genellikle kafa
                            derisinde, dirseklerde, dizlerde ve belde görünür.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Card className="mb-4" style={{ minHeight: "400px" }}>
                        <Card.Img
                          variant="top"
                          src={egzema}
                          style={{
                            width: "100%",
                            maxHeight: "200px",
                            objectFit: "cover",
                          }}
                        />
                        <Card.Body>
                          <Card.Title>Egzama</Card.Title>
                          <Card.Text>
                            Egzama herkes için farklı görünebilir. Döküntü
                            genellikle yüzünüzde, dizlerinizin arkasında,
                            bileklerinizde, ellerinizde veya ayaklarınızda olur.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Card className="mb-4" style={{ minHeight: "400px" }}>
                        <Card.Img
                          variant="top"
                          src={rosocea}
                          style={{
                            width: "100%",
                            maxHeight: "200px",
                            objectFit: "cover",
                          }}
                        />
                        <Card.Body>
                          <Card.Title>Rosacea</Card.Title>
                          <Card.Text>
                            Rosacea ile hayat zor olabilir ama yalnız
                            olmadığınızı unutmayın. Rosacea her gün milyonlarca
                            insanı etkiliyor.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Fonksiyonel Tıpla Cilt Sağlığını Kazanma ve
                            Yaşlanmasını Önleme
                          </Card.Title>
                          <Card.Text>
                            Fonksiyonel tıp, cilt sağlığını kazanmak
                            ,yaşlanmasını önlemek ve yönetmek için bütünsel ve
                            kişiye özel bir yaklaşım sunar. Beslenme
                            seçimlerinin, yaşam tarzı alışkanlıklarının ve
                            hedeflenen müdahalelerin cilt sağlığını desteklemek
                            için birlikte çalıştığı, vücudu birbirine bağlı bir
                            sistem olarak anlamanın önemini vurgular.
                          </Card.Text>
                          <Card.Text>
                            Fonksiyonel tıp, beslenme yetersizlikleri, hormonal
                            dengesizlikler ve çevresel toksinlere maruz kalma
                            gibi yaşlanmanın temel nedenlerine odaklanarak
                            kişiselleştirilmiş ve etkili stratejiler sunar.
                            Bireyleri bu kapsamlı yaklaşımı benimsemeye teşvik
                            etmek, cilt bakımı ve genel sağlık konusunda
                            proaktif ve bilinçli bir bakış açısının faydalarını
                            vurgulayarak daha sağlıklı bir cilde ve daha genç
                            bir görünüme yol açabilir. 
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Text>
                            <strong> Fonksiyonel tıp, </strong>bütünsel bakış
                            açısıyla, cilt sorunlarını giderme ve cilt
                            yaşlanmasını önleme için kapsamlı stratejiler
                            geliştirmek için yaşam tarzı, çevresel faktörler ve
                            sistemik sağlık arasındaki karmaşık bağlantıları
                            araştırır. Bu yaklaşım sadece cildinizin görünür
                            belirtilerini ele almakla kalmaz, aynı zamanda temel
                            nedenleri değerlendirir ve yüzeysel tedavilerin
                            ötesine geçerek sürdürülebilir çözümler sunar.
                            <Card.Text>
                              {" "}
                              Mevcut cilt sorunlarınızın "Kök Nedeni" ni
                              belirlemek için kapsamlı bir değerlendirme
                              sunuyoruz. Temel testlerin ötesine geçerek
                              cildinizin altında neler olup bittiğini ortaya
                              çıkarmanıza yardımcı olabiliriz! Bu, vücudunuzun
                              size cildiniz aracılığıyla dile getirdiği altta
                              yatan koşulları ve neyi iyileştirmeniz gerektiğini
                              ortaya çıkaracak ve anlamanıza yardımcı olacaktır.
                            </Card.Text>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title
                            style={{ textAlign: "center", cursor: "pointer" }}
                            onClick={openModal}
                          >
                            Cilt Sorunlarını ve Yaşlanmasını Fonksiyonel Tıp
                            Perspektifinden Anlamak için Tıklayınız
                          </Card.Title>
                        </Card.Body>
                      </Card>
                      <MyPDFViewer
                        pdfFile={ciltsorunlari}
                        isOpen={showViewer}
                        onClose={closeModal}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Footeralt className="footeralt" />
      </div>
    </div>
  );
}

export default CiltVeOtesi;
