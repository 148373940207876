import React, { useState } from "react";
import { Card, Button, Form} from "react-bootstrap";
import html2pdf from 'html2pdf.js';

const ExozomForm = () => {
  const [name, setName] = useState("");
  const [exozomTreatment, setExozomTreatment] = useState("");
  const [howDidYouHear, setHowDidYouHear] = useState([]);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const handleDownloadPDF = () => {
    const formData = {
        name,
        exozomTreatment,
        howDidYouHear: howDidYouHear.join(", "),
        privacyPolicy,
    };

    const pdfContent = `
        <h2>Ekzozom Tedavisi Talep Formu</h2>
        <p><strong>İsim:</strong> ${formData.name}</p>
        <p><strong>Seçilen Ekzozom Tedavisi:</strong> ${formData.exozomTreatment}</p>
        <p><strong>Bizden Nasıl Haberdar Oldunuz:</strong> ${formData.howDidYouHear}</p>
        <p><strong>Gizlilik Politikası Kabul Edildi mi:</strong> ${formData.privacyPolicy ? "Evet" : "Hayır"}</p>
    `;

    const element = document.createElement("div");
    element.innerHTML = pdfContent;

    html2pdf()
        .from(element)
        .save();
};

  return (
    <div>
      <Card className="mb-4">
        <Card.Body>
          <Card.Title>Ekzozom Tedavisi Talep Formu</Card.Title>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>İsim</Form.Label>
              <Form.Control
                type="text"
                placeholder="İsminizi girin"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formExozomTreatment">
              <Form.Label>Bir ekzozom tedavisi seçin</Form.Label>
              <Form.Control
                as="select"
                value={exozomTreatment}
                onChange={(e) => setExozomTreatment(e.target.value)}
              >
                <option>ExoCİLT - Cilt Bakımı</option>
                <option>ExoSAÇ - Saç Dökülmesi Tedavisi</option>
                <option>ExoİZ - Yara Ve Çatlak Tedavisi</option>
                <option>ExoEKLEM - Ortopedik Tedavi</option>
                <option>ExoERKEK - Erektil Disfonksiyon Tedavisi</option>
                <option>ExoKADIN - Vajinal Gençleştirme</option>
                <option>ExoSPOR - Vücut Performansı Geliştirme</option>
                <option>ExoGENÇ - Yaşlanma Karşıtı Tedavi</option>
                <option>ExoOTİZM - Otizm Spektrum Bozukluğu Destek Tedavi</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formHowDidYouHear">
              <Form.Label>Bizden nasıl haberdar oldunuz?</Form.Label>
              <Form.Check
                type="checkbox"
                label="İnternet"
                onChange={() => setHowDidYouHear(["İnternet"])}
                checked={howDidYouHear.includes("İnternet")}
              />
              <Form.Check
                type="checkbox"
                label="Doktor/Uzman"
                onChange={() => setHowDidYouHear(["Doktor/Uzman"])}
                checked={howDidYouHear.includes("Doktor/Uzman")}
              />
              <Form.Check
                type="checkbox"
                label="Dergiler/Gazeteler"
                onChange={() => setHowDidYouHear(["Dergiler/Gazeteler"])}
                checked={howDidYouHear.includes("Dergiler/Gazeteler")}
              />
              <Form.Check
                type="checkbox"
                label="Akrabalar/Arkadaşlar"
                onChange={() => setHowDidYouHear(["Akrabalar/Arkadaşlar"])}
                checked={howDidYouHear.includes("Akrabalar/Arkadaşlar")}
              />
              <Form.Check
                type="checkbox"
                label="Diğer"
                onChange={() => setHowDidYouHear(["Diğer"])}
                checked={howDidYouHear.includes("Diğer")}
              />
            </Form.Group>
            <Form.Group controlId="formPrivacyPolicy">
              <Form.Check
                type="checkbox"
                label="Gizlilik Politikası (*)"
                checked={privacyPolicy}
                onChange={(e) => setPrivacyPolicy(e.target.checked)}
              />
            </Form.Group>
            <br/>
            <Button variant="secondary" onClick={handleDownloadPDF}>
              Formu İndir
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ExozomForm;
