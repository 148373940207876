import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import functional from "../images/functionak.png";
import regeneratif from "../images/regeneratif.png";
import womanandchild from "../images/womanandchild.png";
import idCheckUp from "../images/checkup.png";
import skin from "../images/skin.png";
import math from "../images/hearthmath.png";
import pre from "../images/preservice.png";
import serum from "../images/serum.png";
import './Services.css';

const ServicesComponent = () => {
  const options = {
    items: 3,
    loop: true,
    margin: 3,
    nav: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      1000: {
        items: 6
      }
    }
  };

  const services = [
    { id: 1, title: 'Fonksiyonel Tıp', imageUrl: functional, linkUrl: '/fonksiyonelTip' },
    { id: 2, title: 'Rejeneratif Tıp', imageUrl: regeneratif, linkUrl: '/rejaneratif' },
    { id: 3, title: 'İntravenöz Destek Tedavisi', imageUrl: serum, linkUrl: '/intravenoz' },
    { id: 7, title: 'Kalp Matematiği ile Stres ', imageUrl: math, linkUrl: '/heartMath' },
    { id: 4, title: 'ID Check UP', imageUrl: idCheckUp, linkUrl: '/idCheckUp' },
    { id: 5, title: 'Kadın & Çocuk Sağlığı', imageUrl: womanandchild, linkUrl: '/kadinvecocuk' },
    { id: 6, title: 'Bütünsel Güzellik', imageUrl: skin, linkUrl: '/butunselGuzellik' },
    { id: 8, title: 'Prekonsepsiyonel Sağlık', imageUrl: pre, linkUrl: '/prekonsepsiyonel' },
  ];

  return (
    <div className="services-container pb-3">
      <OwlCarousel {...options} className="owl-theme">
        {services.map((service) => (
          <div key={service.id} className="item service-item">
            <Link to={service.linkUrl} style={{ textDecoration: 'none' }}>
              <img src={service.imageUrl} alt={service.title} className="service-imagee" />
              <div className="title">{service.title}</div>
            </Link>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default ServicesComponent;
