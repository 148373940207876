import React, { useEffect, useState } from "react";
import CustomNavbar from "../components/navbar/Navbar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Pages.css";
import Footeralt from "../components/footer2/Footeralt";
import TopCard from "../components/topCard/TopCard";
import { Accordion, Container } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";

function Sss() {
  const [topCardHeight, setTopCardHeight] = useState(0);

  useEffect(() => {
    const topCardElement = document.querySelector(".top-card");
    if (topCardElement) {
      const topCardRect = topCardElement.getBoundingClientRect();
      setTopCardHeight(topCardRect.height);
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position = "relative";
        navbarElement.style.backgroundColor = "grey";
      }
    }

    function handleScroll() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position =
          scrollY > topCardHeight ? "fixed" : "relative";
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topCardHeight]);

  return (
    <div>
      <TopCard className="top-card" />
      <div className="navbar-fixed">
        <CustomNavbar />
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-5 pb-3">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Muayenehanemizde Neler Deneyimleyeceksiniz?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Dr. Bengüsu Özkan, sağlığınızın sizinle ve yaşam tarzınızla
                  ilgili her şeyin bir sonucu olduğunu öngörür. Sadece
                  semptomlarınıza bakarak ve size bir reçete vererek değil, aynı
                  zamanda sizi dinleyerek sağlığınıza neyin katkıda bulunduğunu
                  ve sağlığınızı neyin bozduğunu anlayarak size odaklanır.
                  Fonksiyonel tıp bakış açısı ile altta yatan nedenler dahil
                  olmak üzere çeşitli düzeltici ve önleyici yöntemlerle hafiften
                  ciddi sağlık sorunlarına kadar her şeyi ele alan işlevsel bir
                  tıp yaklaşımı uygular.
                </p>
                <p>
                  Fonksiyonel Beslenme önerileri ile kilo verme planı oluşturma.
                </p>
                <ul>
                  <li>
                    Besleyiciler, gıda bazlı takviyeler ve bitkisel destekler
                  </li>
                  <li>Canlandırıcı ve yaşlanma karşıtı tedaviler</li>
                  <li>İntravenöz tedavi</li>
                </ul>
                <p>
                  Rejeneratif tedavi ve stres yönetimi için kalp matematiği
                  egzersizi gibi farklı yöntemlerle de ilgilenir.
                </p>
                <p>
                  Fonksiyonel Tıp hekimi, ilk görüşmenizde dinleyip hikayenizi
                  öğrenerek sizi keşif sürecine alır ve ihtiyacınıza uygun
                  tedavileri düzenler. Fonksiyonel Tıp yaklaşımı ile
                  hastalıkları önlemek ve vücudunuzdaki dengeyi yeniden sağlamak
                  için sizi dinleyen, kendinizi tanımanızı sağlayan bir bakım
                  deneyimleyeceksiniz.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Genelde Hangi Şikayetler ile Başvurulmaktadır?
              </Accordion.Header>
              <Accordion.Body>
                <ul className="section-list custom-list text-left">
                  <li>
                    <FaCheck className="check-icon" /> Baş Ağrısı, Migren
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Alerji, Cilt Sorunları
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Kas ve Eklem Ağrıları
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Kilo Alımı veya Kilo
                    Kaybı
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Kaygı ve Depresif
                    Yakınmalar
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Yüksek Tansiyon,
                    Kolesterol Düzeyleri
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Menopoza ya da Andropoza
                    Bağlı Yakınmalar
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Hafıza Sorunları,
                    Unutkanlık, Alzheimer, Demans
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Bedensel ve Zihinsel
                    Yorgunluk, Sisli Beyin Yakınması
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Zayıf Bağışıklık, Sık
                    Hastalanma, Tekrarlayan veya Kronik Enfeksiyonlar
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Sindirim Sorunları:
                    Kabızlık, İshal, Şişkinlik, Gaz, Reflü, H.Pylori Pozitifliği
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Hangi Kronik Tanı ya da Talebi Olan Danışanlar Integratif ve
                Fonksiyonel Medikal (IFM) Sisteminden Fayda Görür?
              </Accordion.Header>
              <Accordion.Body>
                <ul className="section-list custom-list text-left">
                  <li>
                    <FaCheck /> Ağır Metal Toksisitesi, Akne
                  </li>
                  <li>
                    <FaCheck /> Allerji, Alzheimer Hastalığı ve Defans
                  </li>
                  <li>
                    <FaCheck /> Artrit, Astım
                  </li>
                  <li>
                    <FaCheck /> Barsak Flora Bozukluğu (Geçirgen Barsak
                    Sendromu, Huzursuz Barsak, Otoimmün Barsak Hastalıkları)
                  </li>
                  <li>
                    <FaCheck /> Cilt sorunları, kronik mantar sorunu
                  </li>
                  <li>
                    <FaCheck /> Çevre ve Gıda Allerjileri
                  </li>
                  <li>
                    <FaCheck /> Depresyon ve Anksiyete, Detoksifikasyon,
                    Diyabet, Egzama - Sedef Hastalığı
                  </li>
                  <li>
                    <FaCheck /> Erkek hormon dengesizlikleri (Andropoz,
                    Empotans)
                  </li>
                  <li>
                    <FaCheck /> Fibromiyalji, Gıda Entoleransı ve Sindirim
                    Sistemi Sorunları
                  </li>
                  <li>
                    <FaCheck /> Huzursuz Bacak Sendromu, İnterstisyel Sistit
                  </li>
                  <li>
                    <FaCheck /> İmmün Sistem Sorunları, İnsülin Direnci,
                    prediyabet
                  </li>
                  <li>
                    <FaCheck /> Kalp Sağlığı, Kadın Hastalıkları
                    (Premenstruasyon Sendromu, Menopoz, Kısırlık, Polikistik
                    Over Sendromu)
                  </li>
                  <li>
                    <FaCheck /> Kalıtımsal Hastalıkların taraması vb.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Hangi Akut Sorunları Tedavi Ediyorsunuz?
              </Accordion.Header>
              <Accordion.Body>
                <ul className="section-list custom-list text-left">
                  <li>
                    <FaCheck /> Akut Sinüzit
                  </li>
                  <li>
                    <FaCheck /> Başağrısı, migren atağı
                  </li>
                  <li>
                    <FaCheck /> Boğaz ağrısı, bademcik iltihaplanması
                  </li>
                  <li>
                    <FaCheck /> Bulantı, kusma, ishal
                  </li>
                  <li>
                    <FaCheck /> Dehidratasyon (sıvı eksikliği)
                  </li>
                  <li>
                    <FaCheck /> Döküntü, dermatit, egzama
                  </li>
                  <li>
                    <FaCheck /> Gıda Zehirlenmesi
                  </li>
                  <li>
                    <FaCheck /> Grip
                  </li>
                  <li>
                    <FaCheck /> İdrar Yolu Enfeksiyonu
                  </li>
                  <li>
                    <FaCheck /> Karın ağrısı (böbrek, safra kesesi)
                  </li>
                  <li>
                    <FaCheck /> Mevsimsel Alerjiler
                  </li>
                  <li>
                    <FaCheck /> Öksürük, bronşit
                  </li>
                  <li>
                    <FaCheck /> Üst Solunum Yolu Enfeksiyonu
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Ofisinizde Hangi Sorunlar Kapsam Dışı Bulunuyor?
              </Accordion.Header>
              <Accordion.Body>
                <ul className="section-list custom-list text-left">
                  <li>
                    <FaCheck /> Akut yaralanma
                  </li>
                  <li>
                    <FaCheck /> Kafa Travması
                  </li>
                  <li>
                    <FaCheck /> Düşme
                  </li>
                  <li>
                    <FaCheck /> Kırık kemik, burkulmaya müdahale
                  </li>
                  <li>
                    <FaCheck /> Motorlu taşıt kazaları
                  </li>
                  <li>
                    <FaCheck /> Hayvan ısırıkları
                  </li>
                  <li>
                    <FaCheck /> Göğüs ağrısı, akut kardiyak sıkıntı
                  </li>
                  <li>
                    <FaCheck /> Nöbetler
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Akut Durumlar İçin Randevu Almam Gerekiyor mu?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Akut hizmetlerimiz 14 yaş ve üstü herkes için mevcuttur.
                  Hastalık veya akut gelişen durumlara acil müdahale hizmeti
                  vermek için yerleşik bir hasta olma zorunluluğu yoktur.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Fonksiyonel Tıp Gerçekten İşe Yarıyor mu?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Evet, randevu ile hergün açık randevularımız var. Randevu
                  almak için lütfen bizi arayın ve durumunuz hakkında bize ek
                  bilgi verin. Fonksiyonel Tıp yaklaşımı ile değerlendirilmeyi
                  seçen kişilerin birçoğu durumlarının iyileştiğini fark ediyor.
                  Bunun nedeni, fonksiyonel tıp ile sağlıklarının her yönü
                  iyileştirmeye çalışılır. Ofisten sadece bir reçete ile
                  çıkmazsınız. Sağlık sorununuza ait kök nedeni belirlemek için
                  yüzlerce faktörü gözden geçiriyoruz. Tedavi edici seçeneklerin
                  dahil edildiği bir yaşam planı ile ofisten ayrılıyorsunuz.
                  Elbette işbirliğiniz bu planın sağlığa kavuşmasını
                  sağlayacaktır.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                Ofisiniz ile Geleneksel bir Pediatrik Ofis Arasındaki Fark
                Nedir?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Destekleyici pediatri yaklaşımımız , tamamlayıcı tıbbı
                  geleneksel tıbbın en iyileri ile birleştirir.Odak noktamız;
                  hasta merkezli destek, sadece hastalığın tedavisini önemsemek
                  değil, sağlığı teşvik etmektir. Amacımız mevcut şikayetlerin
                  dinlenip özgeçmiş bilgisini öğrenerek gerekli destek
                  tedavisini çocuğunuzun ihtiyaçlarını karşılayacak şekilde
                  uyarlamaktır.Ayrıca bağışıklık sistemini doğal olarak
                  geliştirerek iyileşmeyi hızlandırmaktır Örneğin ; hastalıklara
                  yatkınlık ile beraber DEHB teşhisi de mevcut ise ikisini ayrı
                  ayrı değerlendirmek yerine her iki sonuca sebep olan temeldeki
                  sorunu yurtiçi ve yurtdışı laboratuvarlar aracılığıyla tespit
                  edip en güncel örn.rejeneratif tedavi protokollerinin de dahil
                  olduğu çocuğunuza özel yaklaşımı bulmayı ve tedavi etmeyi
                  hedeflemekteyiz..
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                Genellikle Hangi Sorunları Değerlendirip, Tedavi Planı
                Oluşturuyorsunuz?
              </Accordion.Header>
              <Accordion.Body>
                <ul className="section-list custom-list text-left">
                  <li>
                    <FaCheck /> Gıda Alerjileri ve Hassasiyetleri
                  </li>
                  <li>
                    <FaCheck /> Vitamin eksiklikleri
                  </li>
                  <li>
                    <FaCheck /> Pediatrik Uykusuzluk
                  </li>
                  <li>
                    <FaCheck /> BDEHB
                  </li>
                  <li>
                    <FaCheck /> Kronik İshal/Kabızlık/Karın Ağrısı
                  </li>
                  <li>
                    <FaCheck /> Tiroid Dengesizliği
                  </li>
                  <li>
                    <FaCheck /> Kötü Beslenme/Beslenme Eksiklikleri
                  </li>
                  <li>
                    <FaCheck /> Astım
                  </li>
                  <li>
                    <FaCheck /> Sedef hastalığı, egzama ve diğer döküntülü cilt
                    rahatsızlıkları
                  </li>
                  <li>
                    <FaCheck /> Tekrarlayan üst solunum yolu
                    enfeksiyonları/tonsillit
                  </li>
                  <li>
                    <FaCheck /> Kilo sorunları
                  </li>
                  <li>
                    <FaCheck /> Yorgunluk
                  </li>
                  <li>
                    <FaCheck /> Migren
                  </li>
                  <li>
                    <FaCheck /> Otoimmün hastalıklar
                  </li>
                  <li>
                    <FaCheck /> Otizm Spektrum Bozukluğu
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                Ofisinize Özgü Tedaviler Nelerdir?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Ofisimiz, çocuğunuzun bağırsağı, genetiği, beslenme durumu
                  veya toksik yük gibi olası sorunları değerlendirmek için
                  fonksiyonel tıp laboratuvarlarını kullanır. Fonksiyonel tıp
                  laboratuvar analizleri tedavi için yararlı verileri
                  toplamamıza yardımcı olur. Ofisimizde tedavi planları
                  aromaterapi , takviyeler , terapötik diyetleri,
                  detoksifikasyon programlarını , ihtiyaca göre rejeneratif
                  tedavi yöntemlerini ve stres yönetimi tekniklerini
                  içermektedir.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Footeralt className="footeralt" />
      </div>
    </div>
  );
}

export default Sss;
