import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import pp from "../images/ProfilePhotos.png";

const AboutUs = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <Container className="py-5">
        <Row className="justify-content-center align-items-center">
          <Col md={6} className="order-md-2 text-center">
            <Image src={pp} alt="About Us" fluid width="450px" height="auto" />
          </Col>
          <Col md={6} className="order-md-1 mb-3">
            <div className="text-center">
              <h2
                className="mb-4 mt-3 text-md-center"
                style={{ color: "#d7b27f", fontWeight: "bold" }}
              >
                Hakkımızda
              </h2>
              <p>
                Dr. Bengüsu Özkan İstanbul’daki muayenehanesinde, her gün en iyi
                halini hissetmeyi hedefleyen ve bu durumun devam etmesini
                isteyen bireylerin ve ailelerin, farklı kariyerdeki ve yoğun
                hayatları olan birçok profesyonel kadın ve erkek danışanların
                sağlık sorunları ya da optimal iyilik hali kazanımı ile ilgili
                sorularına yanıt verir. Fonksiyonel tıp bakış açısı ile her
                insanın vücudunu dengeli ve hastalığa karşı dirençli olması için
                yapması gerekeni tespit edip , bilgilenmesini sağlar.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          <Col md={8} className="text-center mt-3">
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                width: "100%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h3
                  style={{
                    color: "#d7b27f",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Dr. Bengüsu Özkan'ı Tanıyın
                </h3>
                <h3
                  style={{
                    color: "#d7b27f",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  İlhamını Keşfedin ve Sağlık Danışmanlığı için Randevu Alın!
                </h3>
                <iframe
                  style={{ width: "100%", height: "100%", border: "none" }}
                  src="https://www.youtube.com/embed/iCupZIndMfk"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
