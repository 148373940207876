import React, { useEffect, useState } from 'react';
import CustomNavbar from '../components/navbar/Navbar';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import mainphoto1 from "../components/images/mainphoto1.jpg";
import mainphoto2 from "../components/images/mainphoto2.jpg";
import "./Pages.css"; 
import ServicesComponent from '../components/services/Services';
import ImageGallery from '../components/detailsPhoto/detailsPhoto';
import Footeralt from '../components/footer2/Footeralt';
import AboutUs from '../components/aboutMe/AboutMe';
import Features from '../components/cards/Cards';
import TopCard from '../components/topCard/TopCard';

function HomePage() {
  const [topCardHeight, setTopCardHeight] = useState(0);

  useEffect(() => {
    const topCardElement = document.querySelector(".top-card");
    if (topCardElement) {
      const topCardRect = topCardElement.getBoundingClientRect();
      setTopCardHeight(topCardRect.height);
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position = "relative";
        navbarElement.style.backgroundColor = "grey";
      }
    }

    function handleScroll() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position =
          scrollY > topCardHeight ? "fixed" : "relative";
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topCardHeight]);

  return (
    <div>
     <TopCard className="top-card" />
      <div className="navbar-fixed">
        <CustomNavbar />
      </div>
        <div className="content">
          <div className="carousel-wrapper">
            <Carousel 
              showThumbs={false} 
              dynamicHeight={false} 
              showStatus={false} 
              infiniteLoop={true} 
              autoPlay={true} 
              interval={5000} 
              stopOnHover={false} 
              showArrows={true}
              showIndicators={false}
            >
              <div className="slide">
                <div className="image-with-text-container">
                  <img src={mainphoto1} alt='p1' />
                </div>
              </div>
              <div className="slide">
                <div className="image-with-text-container">
                  <img src={mainphoto2} alt='p2' />
                </div>
              </div>
            </Carousel>
          </div>
          <AboutUs/>
          <div style={{backgroundColor: '#d9d9d9'}}>
            <ImageGallery/>
            <ServicesComponent/>
          </div>
          <Features/>
        </div>
      <div style={{backgroundColor: '#c6c6c6'}}>
        <Footeralt className="footeralt" />
      </div>
    </div>
  );
}

export default HomePage;