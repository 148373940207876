import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import TopCard from "../components/topCard/TopCard";
import CustomNavbar from "../components/navbar/Navbar";
import Footeralt from "../components/footer2/Footeralt";
import "./Pages.css";

function Intrevanoz() {
  const [topCardHeight, setTopCardHeight] = useState(0);

  useEffect(() => {
    const topCardElement = document.querySelector(".top-card");
    if (topCardElement) {
      const topCardRect = topCardElement.getBoundingClientRect();
      setTopCardHeight(topCardRect.height);
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position = "relative";
        navbarElement.style.backgroundColor = "grey";
      }
    }

    function handleScroll() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position =
          scrollY > topCardHeight ? "fixed" : "relative";
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topCardHeight]);

  return (
    <div>
      <TopCard className="top-card" />
      <div className="navbar-fixed">
        <CustomNavbar />
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container>
          <Row>
            <Col md={12}>
              <Card className="mb-4 mt-3">
                <Card.Title className="mt-3" style={{ textAlign: "center" }}>
                  İntravenöz (IV) Tedavi
                </Card.Title>
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>Yaklaşımımız</Card.Title>
                          <Card.Text>
                            Önleme ve semptom tedavisine odaklanırken etkili
                            tedavi seçenekleri sunmayı hedeflemektedir.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Kişiselleştirilmiş IV Planı Oluşturalım
                          </Card.Title>
                          <Card.Text>
                            Deneyimli fonksiyonel tıp hekimimiz, tüm
                            semptomlarınızı ele alan, dengeyi yeniden sağlayan
                            ve vücudunuzun tüm potansiyelini ortaya çıkaran
                            kişiselleştirilmiş bir plan tasarlayacaktır.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>İntravenöz (IV) Tedavi Nedir?</Card.Title>
                          <Card.Text>
                            İntravenöz (IV) kelimesi basitçe "damar içinde"
                            anlamına gelir ve bu tür bir tedavi, sıvı maddelerin
                            doğrudan bir damara verilmesidir. İntravenöz (IV)
                            tedavi, hücre içi besin eksikliklerini düzeltmek
                            için vitaminleri, mineralleri ve amino asitleri
                            doğrudan kan dolaşımına vererek hücreleri destekleme
                            yöntemidir. Gerçek şu ki, yediğimiz besinlerin
                            sadece yaklaşık %20-30'u vücuda emilir. Geri kalanı
                            sindirim sürecinde kaybolur. IV tedaviler, vücuda
                            sıvı ve ilaç vermenin en hızlı ve en etkili yoludur.
                            İntravenöz olarak verilen besinlerin yaklaşık %100'ü
                            vücut hücreleri tarafından kullanılabilir. Peki
                            ağızdan tükettiğimiz vitamin ve minerallerinizin
                            %100 emilimini neden sağlayamıyorsunuz?Bu kadar
                            yüksek dozların oral alımı, sindirim sürecinden
                            kaynaklanan emilim sınırlamaları nedeniyle mümkün
                            değildir. Hastaların böyle bir tedaviyi sağlamak
                            için bir günde ağızdan almakları gereken miktar
                            neredeyse 10 katı kadar besin olmalıdır.Bunun iyi
                            bir örneği, bir tür virüs nedeniyle soğuk
                            algınlığının üstesinden gelmeye çalışırken C
                            Vitamini kullanılmasıdır. C vitamininin antiviral
                            etkileri, kan seviyeleri 10-15 mg/dL civarında
                            olduğunda görülür. Bu seviye IV tedavisi ile elde
                            edilebilir ancak oral olarak elde edilemez. Oral
                            dozaj tipik olarak 1.2-4.0 mg/dL civarında kan
                            seviyesine ulaşır .Özellikle viral/bakteriyel
                            enfeksiyonlar, kanser, Lyme hastalığı ve kronik
                            yorgunluk sendromu ve fibromiyalji gibi tedavisi zor
                            durumlar için etkili IV tedavileri, hastaların bu
                            yaklaşımı seçmelerinin önemli bir nedeni olmaya
                            devam etmektedir.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>Neden IV Tedavi Seçilmeli?</Card.Title>
                          <Card.Text>
                            <ul>
                              <li>
                                <strong>Garantili Emilim:</strong> IV tedavisi
                                ile vitaminlerin tamamen ve hızlı bir şekilde
                                kan dolaşımınıza emildiğinden emin
                                olabilirsiniz, bu da vücudunuzun onları hemen
                                kullanmasını sağlar.
                              </li>
                              <li>
                                <strong>Enerji Artışı:</strong> Yorgunluk,
                                anksiyete, soğuk algınlığı veya solunum yolu
                                hastalığından muzdaripseniz, IV tedaviler
                                yenilenmiş ve enerjik hissetmenize yardımcı
                                olmak için bağışıklık sisteminizi güçlendirmeye
                                yardımcı olabilir.
                              </li>
                              <li>
                                <strong>Detoksifikasyon:</strong> Günlük
                                ortamınızdaki toksinler sağlığınıza zarar verir.
                                IV terapi, vücudunuzun zararlı toksinleri daha
                                etkili bir şekilde temizlemek için ihtiyaç
                                duyduğu ek yardımı sağlar.
                              </li>
                              <li>
                                <strong>Bağışıklık Sistemi Desteği:</strong> IV
                                tedaviler, bağışıklık sistemini desteklemede
                                başarılı bir adım olabilir. Örneğin terapötik C
                                vitamini dozlarının kanser hücreleri için toksik
                                olduğu gösterilmiştir ve bağışıklık
                                oluşturmadaki etkinlikleri ile iyi
                                bilinmektedir.
                              </li>
                              <li>
                                <strong>Acil Restorasyon:</strong> Yediğiniz
                                yiyecekleri tamamen sindirmek iki güne kadar
                                mümkün olabilir. IV terapi ile uygulanan
                                desteklerin vücudunuzdaki olumlu etkilerini
                                neredeyse anında hissetmeye başlayabilirsiniz.
                                Birkaç saat içinde IV besinlerinin tüm
                                etkilerini hissedebilirsiniz.
                              </li>
                              <li>
                                <strong>Viral Önleme IV Terapi:</strong> Güçlü
                                bağışıklık savunma tepkinizi desteklemek için
                                yüksek doz C vitamini de dahil olmak üzere
                                bağışıklık artırıcı vitaminlerle doludur!Bu IV
                                hem takibimizdeki hastalar hem de sadece bu
                                tedavilerden istifade etmek isteyenler için
                                mevcuttur.
                              </li>
                            </ul>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Footeralt />
      </div>
    </div>
  );
}

export default Intrevanoz;
