import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import "@react-pdf-viewer/core/lib/styles/index.css";
import TopCard from "../components/topCard/TopCard";
import CustomNavbar from "../components/navbar/Navbar";
import Footeralt from "../components/footer2/Footeralt";
import ekzozom from "../components/documents/ekzozom.pdf";
import ExozomForm from "../components/ekzozomform";
import "./Pages.css";
import MyPDFViewer from "../components/pdfViewer";

function RejaneratifTıp() {
  const [showViewer, setShowViewer] = useState(false);
  const [topCardHeight, setTopCardHeight] = useState(0);
  const openModal = () => {
    setShowViewer(true);
    document.body.style.overflow = "hidden";
  };
  const closeModal = () => {
    setShowViewer(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const topCardElement = document.querySelector(".top-card");
    if (topCardElement) {
      const topCardRect = topCardElement.getBoundingClientRect();
      setTopCardHeight(topCardRect.height);
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position = "relative";
        navbarElement.style.backgroundColor = "grey";
      }
    }

    function handleScroll() {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const navbarElement = document.querySelector(".navbar-fixed");
      if (navbarElement) {
        navbarElement.style.position =
          scrollY > topCardHeight ? "fixed" : "relative";
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topCardHeight]);

  return (
    <div>
      <TopCard className="top-card" />
      <div className="navbar-fixed">
        <CustomNavbar />
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container>
          <Row>
            <Col md={12}>
              <Card className="mb-4 mt-3">
                <Card.Title className="mt-4" style={{ textAlign: "center" }}>
                  Regeneratif Tıp
                </Card.Title>
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Text>
                            Rejeneratif tıp, öncelikle semptomları tedavi etmeye
                            odaklanan mevcut klinik stratejinin aksine yaş,
                            hastalık, travma sebebiyle veya doğuştan hasar
                            görmüş dokuya odaklanmayı amaçlamaktadır. Bu
                            sonuçları gerçekleştirmek için kullanılan araçlar
                            doku mühendisliği uygulamaları, hücresel tedaviler
                            ve tıbbi cihazlar ve yapay organlardır. Bu
                            yaklaşımların kombinasyonları, en çok ihtiyaç
                            duyulan yerlerde doğal iyileşme sürecimizi
                            geliştirebilir veya kalıcı olarak hasar görmüş bir
                            organın işlevini üstlenebilir. Rejeneratif tıp,
                            insanlığın karşılaştığı en zorlu tıbbi sorunlardan
                            bazılarına çözüm bulmak için biyoloji, kimya,
                            bilgisayar bilimi, mühendislik, genetik, tıp,
                            robotik ve diğer alanlardaki uzmanları bir araya
                            getiren bir alandır. Bedenimiz yaralandığında veya
                            hastalık durumunda iyileşmek ve savunmak için
                            doğuştan iyileşme gücüne sahiptir. İyileşmek için
                            vücudun gücünden yararlanmak ve daha sonra klinik
                            olarak hızlandırmak mümkün olsaydı ne olurdu? Umut
                            verici rejeneratif tıp alanı, hasarlı doku ve
                            organların yapısını ve işlevini eski haline getirmek
                            için çalışmaktadır. Ayrıca kalıcı olarak hasar gören
                            organlar için çözümler oluşturmak için çözümler
                            üretmektedir. Bu yaklaşımın amacı, daha önce tedavi
                            edilemeyen yaralanmaları ve hastalıkları potansiyel
                            olarak tedavi edecek dönüştürücü sağlık çözümleri
                            geliştirmektir.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>EKSOZOM TERAPİSİ</Card.Title>
                          <Card.Text>
                            Eksozomlar, mezenkimal kök hücreler de dahil olmak
                            üzere çeşitli hücre tipleri tarafından salgılanan,
                            tipik olarak 30 ila 150 nm boyutunda değişen
                            membrana bağlı veziküllerdir. Hücreler arasında
                            proteinleri, lipidleri ve nükleik asitleri (mRNA ve
                            mikroRNA gibi) aktararak hücreden hücreye iletişimde
                            çok önemli bir rol oynarlar. Eksozomlar içindeki bu
                            özellik, alıcı hücrelerin davranışını ve işlevini
                            etkileyerek onları çeşitli fizyolojik ve patolojik
                            süreçlerde kilit oyuncular haline getirebilir.
                            Eksozomlar hücre içerisine giderek oradaki hatalı ya
                            da eksik olan kısmı tamir ederek tekrar genç hücre
                            gibi çalışmalarını sağlayabilen mükemmel yazılıma
                            sahip hücre yapılarıdır. Rejeneratif,
                            anti-inflamatuar, anjiyojenik ve immünomodülatör
                            özelliklere sahip oldukları bulunmuştur. Bu da
                            onları doku hasarı, dejeneratif hastalıklar ve
                            enflamatuar bozukluklar da dahil olmak üzere çok
                            çeşitli durumları tedavi etmek için umut verici
                            adaylar haline getirir.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>
                            Eksozomlar hangi durumlarda uygulanır?
                          </Card.Title>
                          <ul>
                            <li>
                              <strong>Doku onarımı ve rejenerasyonu</strong>
                              <ul>
                                <li>
                                  Deri, kemik ve kıkırdak gibi hasarlı dokuların
                                  yenilenmesine yardımcı olarak hücre
                                  çoğalmasını, göçünü ve farklılaşmasını teşvik
                                  edebilirler. Örneğin akne izleri, yara
                                  izlerinin görünümünü iyileştirme, egzama,
                                  yüzdeki kırışıklıkların azaltılması, cilt
                                  yaralanmalarında faydalı olabilirler.
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong>Yara iyileşmesi</strong>
                              <ul>
                                <li>
                                  Eksozomlar, yeni kan damarlarının oluşumunu
                                  uyarabilir, iltihabı azaltabilir ve yara
                                  iyileşmesini sağlayacak şekilde dokunun
                                  yeniden şekillenmesini teşvik edebilir.Örneğin
                                  diyabetik ayak gibi uzun süreli geçmeyen
                                  yaralarda faydalı sonuçlar sağlayabilir.
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong>
                                Anti-inflamatuar ve immünomodülatör etkiler
                              </strong>
                              <ul>
                                <li>
                                  Bağışıklık hücresi fonksiyonlarını modüle
                                  edebilir, iltihabı azaltabilir ve doku
                                  onarımını teşvik edebilir, bu da onları
                                  otoimmün ve enflamatuar hastalıkların
                                  tedavisinde faydalı hale
                                  getirebilir.Bağışıklık sistemini olumlu yönde
                                  etkileyebilirler.Örneğin kanser immünoterapisi
                                  gibi durumlarda potansiyel etki
                                  sağlayabilirler.
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong>Nöroproteksiyon</strong>
                              <ul>
                                <li>
                                  Eksozomların uygulanması ile Alzheimer
                                  hastalığı ve Parkinson hastalığı gibi
                                  nörodejeneratif bozukluklarda nöronları
                                  hasardan korumak ve nöral rejenerasyonu teşvik
                                  etmek amaçlanmaktadır.
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong>Saç ve cilt terapisi</strong>
                              <ul>
                                <li>
                                  Günümüzde içerdiği ajanlar sayesinde
                                  eksozomlar ile doğal yoldan cildin daha genç
                                  görünmesi , egzema, vitiligo gibi cilt
                                  hastalıklarının ve saç dökülmesinin gerilemesi
                                  amaçlanmaktadır.
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong>Kardiyoprotektif etki</strong>
                              <ul>
                                <li>
                                  Hipoksi ve enflamasyona bağlı sorunlarda
                                  eksozomların içeriğini ve alıcı hücreler
                                  içinde salınımlarını modüle ederek kalp
                                  aktivitesinin hem iyileşmesine hem de kardiyak
                                  fonksiyonu arttırmayı sağladığı
                                  gösterilmiştir. Kalp ve dolaşım sistemi
                                  rahatsızlıklarında eksozom tedavisi ile
                                  dolaşım sistemi içinde hücrelerarası iletişimi
                                  ve dolaşım sistemi fonksiyonlarını
                                  düzeltebilmesi amaçlanmaktadır.
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong>Kanser tedavisi</strong>
                              <ul>
                                <li>
                                  Eksozom terapisi ile kanser tedavisinde
                                  uygulanan ilaçların etkinliğini arttırması
                                  hedeflenmektedir.
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong>Romatolojik hastalıklar</strong>
                              <ul>
                                <li>
                                  Eksozomlar, iltihaplı romatolojik
                                  hastalıkların tedavisinde destek tedavisi
                                  amaçlı uygulanabilirler.
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong>Otizm spektrum bozukluğu</strong>
                              <ul>
                                <li>
                                  Eksozom ve rutin tedavilerin birleşimi ile
                                  otizm spektrum bozukluğu tanılı vakalarda
                                  bilişsel gelişim, öğrenme kapasitesinde artış,
                                  sosyal uyum gelişimi gibi alanlarda ilerleme
                                  hedeflenmektedir.
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title
                            style={{ textAlign: "center", cursor: "pointer" }}
                            onClick={openModal}
                          >
                            Mezenkimal Kök Hücre Ekzozomları ile Diğer
                            Kaynaklardan Elde Edilen Eksozomlar Arasındaki
                            Farklar için Tıklayınız
                          </Card.Title>
                        </Card.Body>
                      </Card>
                      <MyPDFViewer
                        pdfFile={ekzozom}
                        isOpen={showViewer}
                        onClose={closeModal}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title style={{ textAlign: "center" }}>
                            Tedavilerimiz ve Ekzozom Tedavisi Avantajlarımız
                            Hakkında Daha Fazlasını Keşfedin
                          </Card.Title>
                          <Card className="mb-4">
                            <Card.Body>
                              <Card.Title>
                                Cilt için Eksozom (ExoCilt)
                              </Card.Title>
                              <Card.Text>
                                Eksozomlar, özellikle cilde derinlemesine
                                verildiğinde yüzün doğal iyileşmesini
                                artırabilir. Cilt, içten dışa doğal ve sağlıklı
                                bir şekilde kendini yeniden inşa edebilecektir.
                                Eksozom yüz gençleştirmesi, tedavi edilen
                                bölgelerde cilt kolajeni altı kata kadar
                                artırabilir ve elastin seviyelerini %300'e kadar
                                artırabilir. Cilt yaşlanmasında mezenkimal kök
                                hücreden (MSC) türeyen eksozom tedavisi için
                                ideal hastalar şunları içerebilir:
                                Kırışıklıklar, ince çizgiler ve yaşa bağlı hacim
                                kaybı gibi yaşlanma belirtilerini ele almak için
                                cerrahi olmayan alternatifler arayan hastalar,
                                MSC'den türetilmiş eksozom tedavisinden
                                yararlanabilir. Cilt elastikiyetinin azalması,
                                kollajen bozulması ve deri altı yağ kaybı dahil
                                olmak üzere yaşa bağlı cilt değişiklikleri olan
                                hastalar, MSC türevli eksozom tedavisini
                                kollajen sentezini teşvik etmede, cilt hücresi
                                çoğalmasını ve göçünü desteklemede ve hücreleri
                                oksidatif stresten korumada faydasını görürler.
                                Cilt yaşlanmasını ele almak için daha doğal bir
                                tedavi seçeneği arayan hastalar, vücudun kendi
                                rejeneratif yeteneklerinden yararlandığı için
                                MSC'den türetilmiş eksozom tedavisinden memnun
                                kalırlar. Hassas cilde veya alerjiye sahip
                                belirli bileşenlere alerjisi olan hastalar,
                                MSC'den türetilmiş eksozom tedavisini daha
                                biyouyumlu ve advers reaksiyonlara neden olma
                                olasılığının daha düşük olduğu için tercih
                                edebilirler. Günümüzde lazer tedavisi, mikro
                                iğneleme veya kimyasal soyma gibi diğer cilt
                                gençleştirme tedavileri alan hastalar, genel
                                sonuçları geliştirmek için tamamlayıcı bir
                                tedavi olarak MSC'den türevli eksozom
                                tedavisinden yararlanabilir.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                          <Card className="mb-4">
                            <Card.Body>
                              <Card.Title>
                                Saç Dökülmesi için Eksozom (ExoSaç)
                              </Card.Title>
                              <Card.Text>
                                Mezenkimal kök hücre eksozomları, rejeneratif ve
                                immünomodülatör özellikleri nedeniyle saç
                                dökülmesi için potansiyel bir tedavi seçeneği
                                olarak ortaya çıkmıştır. Birkaç çalışma, bu
                                eksozomların saç folikülü biyolojisinde yer alan
                                çeşitli hücresel ve moleküler süreçlere etki
                                ederek saç büyümesini teşvik edebileceğini öne
                                sürmüştür. Saç dökülmesinde MSC'den türeyen
                                eksozom tedavisi için ideal hastalar şunları
                                içerebilir: Erkek tipi kellik veya kadın tipi
                                saç dökülmesi olarak da bilinen androgenetik
                                alopesi olan hastalar, saç folikülü
                                rejenerasyonunu destekleyebileceği ve saç
                                döngüsünün anajen (büyüme) fazını uzatabileceği
                                için MSC'den türevli eksozom tedavisinden
                                yararlanabilir. Yamalı saç dökülmesine neden
                                olan bir otoimmün bozukluk olan alopecia areata
                                olan hastalar için MSC türevi eksozom tedavisi
                                iltihabı azaltmada, bağışıklık tepkisini modüle
                                etmede ve saçın yeniden büyümesini teşvik etmede
                                yardımcı olabilir. Saç ekimi veya kafa derisi
                                küçültme ameliyatının aksine saç dökülmelerini
                                gidermek için cerrahi olmayan bir alternatif
                                arayan hastalar, MSC'den türetilmiş eksozom
                                tedavisini düşünebilirler. Minoksidil,
                                finasterid veya kortikosteroid enjeksiyonları
                                gibi geleneksel saç dökülmesi tedavilerini
                                tatmin edici sonuçlar olmadan deneyen hastalar,
                                MSC'den türetilen eksozom tedavisini alternatif
                                bir seçenek olarak düşünebilirler. Hastaların
                                genel sağlıkları iyi olmalı, aktif
                                enfeksiyonlar, kontrolsüz tıbbi durumlar veya
                                tedaviye kontrendikasyonlar olmamalıdır. Genel
                                sağlığın iyi olması, başarılı tedavi olasılığını
                                artırır ve potansiyel riskleri ve
                                komplikasyonları azaltır.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                          <Card className="mb-4">
                            <Card.Body>
                              <Card.Title>
                                Yara İzleri için Eksozom (Exoİz)
                              </Card.Title>
                              <Card.Text>
                                Eksozomlar doku rejenerasyonunda önemli roller
                                oynarlar.Ana hücrelerine benzer bir işleve sahip
                                olarak, taşıdıkları biyoaktif maddeleri pH
                                ortamları, yüksek sıcaklık ve tekrarlanan donma
                                ve çözme gibi olumsuz koşullardan koruyarak
                                depolanması ve taşınması daha kolaydır. Bu
                                nedenle, eksozom tedavisi PRP'den daha güvenli
                                ve daha verimli olabilir. Çatlaklarda ve
                                yaralarda MSC'den türevli eksozom tedavisi için
                                ideal hastalar şunları içerebilir: Hamilelikten
                                kaynaklanan çatlaklar, hızlı kilo alımı veya
                                kaybı olan hastalar, kollajen sentezini teşvik
                                edebileceği ve cilt elastikiyetini
                                iyileştirebileceği ve potansiyel olarak
                                çatlakların görünümünü azaltabileceği için
                                MSC'den türevli eksozom tedavisinden
                                yararlanabilir. Hipertrofik, keloid veya atrofik
                                skarlar gibi farklı yara türlerine sahip
                                hastalar için MSC'den türetilmiş eksozom
                                tedavisi doku onarımını teşvik etmede, iltihabı
                                azaltmada ve yara izlerinin genel görünümünü
                                iyileştirmede yardımcı olabilir. Cerrahi
                                revizyon veya lazer tedavilerinin aksine
                                çatlaklarını ve izlerini gidermek için cerrahi
                                olmayan bir alternatif arayan hastalar, MSC'den
                                türetilmiş eksozom tedavisini düşünebilir.
                                Topikal kremler, silikon jel tabakalar veya
                                kortikosteroid enjeksiyonları gibi çatlaklar ve
                                yara izleri için geleneksel tedavileri tatmin
                                edici sonuçlar olmadan deneyen hastalar, MSC'den
                                türetilmiş eksozom tedavisini alternatif bir
                                seçenek olarak düşünebilirler. Çatlaklar ve yara
                                izleri için daha doğal ve biyouyumlu bir tedavi
                                seçeneği arayan hastalar, hücrelerin rejeneratif
                                yeteneklerini kullandığı için MSC'den türevli
                                eksozom tedavisini uygun bulabilirler.
                                Hastaların aktif enfeksiyonlar, kontrolsüz tıbbi
                                durumlar veya tedaviye kontrendikasyonlar
                                olmadan genel sağlıkları iyi olmalıdır. İyi
                                sağlık, başarılı tedavi olasılığını artırır ve
                                potansiyel riskleri ve komplikasyonları azaltır.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                          <Card className="mb-4">
                            <Card.Body>
                              <Card.Title>
                                Ortopedi için Eksozom (ExoEklem)
                              </Card.Title>
                              <Card.Text>
                                Ortopedik tedaviler için eksozom tedavisi,
                                osteoartrit, kıkırdak dejenerasyonu, tendon veya
                                bağ yaralanmaları, tendinit, kemik kırıkları,
                                avasküler nekroz, kıkırdak yaralanmaları,
                                iltihaplanma ile ilişkili durumlar, doku hasarı
                                ve işlev kaybı için kullanılır. Ortopedik
                                uygulamalarda MSC'den türevli eksozom tedavisi
                                için ideal hastalar şunları içerebilir: Ağrı,
                                iltihaplanma ve eklem dejenerasyonu yaşayan
                                osteoartritli hastalar, kıkırdak rejenerasyonunu
                                teşvik etmeye, iltihabı azaltmaya ve bağışıklık
                                tepkisini modüle etmeye yardımcı
                                olabileceğinden, MSC'den türevli eksozom
                                tedavisinden yararlanabilir. Tendinit,
                                tendinopati veya kısmi yırtık gibi tendon veya
                                bağ yaralanmaları olan hastalarda, MSC'den
                                türevli eksozom tedavisi doku onarımını teşvik
                                etmede, iltihabı azaltmada ve iyileşme sürecini
                                hızlandırmada yardımcı olabilir. İyileşmesi
                                yavaş olan veya düzgün bir şekilde iyileşmeyen
                                kemik kırıkları veya birleşme olmayan hastalar,
                                kemik rejenerasyonunu teşvik edebileceği,
                                anjiyogenezi artırabileceği ve genel iyileşme
                                sürecini destekleyebileceği için MSC'den türevli
                                eksozom tedavisinden yararlanabilir. Eklem
                                replasman ameliyatı geçiren hastalar, MSC'den
                                türevli eksozom tedavisini doku onarımını teşvik
                                etmede, iltihabı azaltmada ve iyileşme süresini
                                potansiyel olarak kısaltmada faydalı
                                bulabilirler. Kemik dokusunun kanlanma eksikliği
                                nedeniyle öldüğü bir durum olan avasküler
                                nekrozlu hastalar, anjiyogenezi teşvik
                                edebileceği, kemik rejenerasyonunu
                                destekleyebileceği ve iltihabı azaltabileceği
                                için MSC'den türevli eksozom tedavisinden
                                yararlanabilir. Kıkırdak yaralanmaları veya
                                kondral lezyonlar gibi kusurları olan hastalar,
                                kıkırdak rejenerasyonunu ve onarımını teşvik
                                edebileceğinden MSC'den türetilmiş eksozom
                                tedavisinden yararlanabilir.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                          <Card className="mb-4">
                            <Card.Body>
                              <Card.Title>
                                Erektil Disfonksiyon için Eksozom (ExoErkek)
                              </Card.Title>
                              <Card.Text>
                                Mezenkimal kök hücrelerden (MSC'ler) türetilen
                                eksozomlar, erektil disfonksiyon (ED) için
                                potansiyel bir terapötik seçenek olarak
                                incelenmiştir. MSC'den türetilen eksozomlar,
                                ED'nin altında yatan nedenleri potansiyel olarak
                                iyileştirebilen büyüme faktörleri, sitokinler ve
                                mikroRNA'lar gibi çeşitli moleküller içerir.
                                Erektil disfonksiyonda MSC'den türevli eksozom
                                tedavisi için ideal hastalar şunları içerebilir:
                                Kardiyovasküler hastalık, diyabet veya periferik
                                vasküler hastalığı olanlar gibi penise kan
                                akışının zayıf olmasından kaynaklanan ED'li
                                hastalar, anjiyogenezi teşvik edebileceği ve kan
                                akışını iyileştirebileceği için MSC'den türevli
                                eksozom tedavisinden yararlanabilir. Prostat
                                ameliyatı geçirmiş veya omurilik yaralanmaları
                                olanlar gibi sinir hasarından kaynaklanan ED'li
                                hastalar, MSC'den türevli eksozom tedavisini
                                sinir rejenerasyonunu teşvik etmede ve erektil
                                fonksiyonu iyileştirmede yardımcı bulabilirler.
                                Penis implantları veya rekonstrüktif cerrahinin
                                aksine, ED'lerini ele almak için cerrahi olmayan
                                bir alternatif arayan hastalar, MSC'den türevli
                                eksozom tedavisini düşünebilirler. Oral ilaçlar,
                                vakum cihazları veya intrakavernosal
                                enjeksiyonlar gibi geleneksel ED tedavilerini
                                tatmin edici sonuçlar olmadan deneyen hastalar,
                                MSC'den türevli eksozom tedavisini alternatif
                                bir seçenek olarak düşünebilirler. ED için daha
                                doğal ve biyouyumlu bir tedavi seçeneği arayan
                                hastalar, mezenkimal hücrelerinin rejeneratif
                                yeteneklerini kullandığı için MSC'den türevli
                                eksozom tedavisini uygun bulabilirler.
                                Hastaların genel sağlıkları iyi olmalı, aktif
                                enfeksiyonlar, kontrolsüz tıbbi durumlar veya
                                tedaviye kontrendikasyonlar olmamalıdır.
                                Sağlıklı bireylere uygulama başarılı tedavi
                                olasılığını artırır ve potansiyel riskleri ve
                                komplikasyonları azaltır.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                          <Card className="mb-4">
                            <Card.Body>
                              <Card.Title>
                                Vajinal Gençleştirme için Eksozom (ExoKadın)
                              </Card.Title>
                              <Card.Text>
                                Mezenkimal kök hücre (MSC) eksozomları,
                                rejeneratif, anti-inflamatuar ve anjiyojenik
                                özellikleri nedeniyle vajinal gençleştirme
                                tedavisi için potansiyele sahip olabilir. Kadın
                                sağlığı için MSC eksozom tedavisinden
                                yararlanabilecek bazı olası hasta grupları
                                şunlardır: Doğum sonrası vajinal doku yapısında
                                ve fonksiyonlarında değişiklikler yaşayan
                                kadınlar, vajinal duvarın elastikiyetini ve
                                sıkılığını geri kazanmaya yardımcı olmak için bu
                                tedaviden yararlanabilir. Menopoz sırasındaki
                                hormonal değişiklikler kolajen üretiminde
                                azalmaya ve vajinal dokuların incelmesine yol
                                açabilir. MSC eksozom tedavisi, bu hasta
                                grubunda kollajen sentezini iyileştirmeye ve
                                vajinal dokuları gençleştirmeye yardımcı
                                olabilir. Bazı kadınlar zayıflamış pelvik taban
                                kasları ve destekleyici dokular nedeniyle stres
                                üriner inkontinans yaşarlar. MSC eksozom
                                tedavisi, bu dokuların gücünü ve işlevini
                                iyileştirmeye yardımcı olarak inkontinans
                                semptomlarını azaltabilir. Bazı kadınlar vajinal
                                dokulardaki değişiklikler nedeniyle cinsel his
                                veya istekte azalma yaşayabilir. MSC eksozom
                                tedavisi, vajinal doku yapısını ve işlevini
                                potansiyel olarak geliştirerek cinsel deneyim ve
                                memnuniyetin artmasına neden olabilir. Vajinal
                                atrofi veya kuruluk olan kadınlar için MSC
                                eksozom tedavisi vajinanın eski sağlığının geri
                                kazanılmasına yardımcı olabilir.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                          <Card className="mb-4">
                            <Card.Body>
                              <Card.Title>
                                Vücut Performansı için Eksozom (ExoSport)
                              </Card.Title>
                              <Card.Text>
                                Mezenkimal kök hücre (MSC) eksozomları,
                                rejeneratif, immünomodülatör ve anti-inflamatuar
                                özellikleri nedeniyle potansiyel önleyici spor
                                performansı göstermiştir. Çeşitli biyoaktif
                                moleküllerin doğal taşıyıcıları olan MSC
                                eksozomları, sporcularda vücut performansını ve
                                iyileşmeyi artırabilir. Spor performans
                                tedavilerinde mezenkimal kök hücre (MSC) eksozom
                                bazlı tedaviler için uygun atletik
                                performanslarını, iyileşmelerini ve genel
                                sağlıklarını geliştirmek isteyen bireyler veya
                                gruplar olacaktır. Sürekli olarak fiziksel
                                sınırlarını zorlayan üst düzey sporcular, kas
                                iyileşmesini, eklem ve tendon sağlığını ve
                                kardiyovasküler fonksiyonu iyileştirmek için MSC
                                eksozom tedavilerinden yararlanabilir. Daha
                                hızlı iyileşme ve daha az yaralanma riski, daha
                                iyi performansa ve rekabet avantajına yol
                                açabilir. Koşu, bisiklet veya kuvvet antrenmanı
                                gibi düzenli fiziksel aktivite yapan insanlar,
                                dayanıklılığı, performansı ve egzersiz sonrası
                                iyileşmeyi artırmak için MSC eksozom
                                terapilerinden yararlanabilir. Sporcularının
                                performansını ve iyileşmesini optimize etmek
                                isteyen spor organizasyonları, kapsamlı eğitim
                                ve iyileşme programlarının bir parçası olarak
                                MSC eksozom tedavileri ile ilgilenebilir.
                                Performans, kas gücü ve eklem sağlığında yaşa
                                bağlı düşüşler yaşayan yaşlı sporcular, bu
                                etkilere karşı koymak ve atletik yeteneklerini
                                korumak için MSC eksozom tedavilerinden
                                yararlanabilir. Sporla ilgili yaralanmalar (kas
                                suşları, tendon yırtıkları veya eklem sorunları
                                gibi) yaşayan kişiler, iyileşme sürecini
                                hızlandırmak ve spor veya fiziksel
                                aktivitelerine daha hızlı bir şekilde geri
                                dönmeyi kolaylaştırmak için MSC eksozom
                                terapilerinden yararlanabilir.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                          <Card className="mb-4">
                            <Card.Body>
                              <Card.Title>
                                Yaşlanma Karşıtı Eksozom (ExoGenç)
                              </Card.Title>
                              <Card.Text>
                                Mezenkimal kök hücre (MSC) eksozomları, yaşa
                                bağlı koşulları geriletmek ve genel sağlığı
                                geliştirmek için terapötik bir seçenek olarak
                                ortaya çıkmıştır. MSC'lerden türetilen
                                eksozomların rejeneratif, immünomodülatör ve
                                anti-inflamatuar özelliklere sahip olduğu ve
                                onları yaşlanma karşıtı tedaviler için ideal
                                adaylar haline getirdiği gösterilmiştir.
                                Yaşlanmanın etkilerine karşı koymak ve genel
                                sağlıklarını ve yaşam kalitelerini iyileştirmek
                                isteyen bireyler , yaşlanma karşıtı mezenkimal
                                kök hücre (MSC) eksozom tedavileri için uygun
                                adaylar olacaktır. Cilt görünümü, eklem
                                fonksiyonu, kas gücü ve bilişsel işlev gibi
                                sağlığın çeşitli yönlerinde yaşa bağlı düşüşler
                                yaşayan bireyler, sağlıklı yaşlanmayı teşvik
                                etmek ve yaşam kalitelerini korumak için MSC
                                eksozom tedavilerinden yararlanabilirler.
                                Osteoartrit, nörodejeneratif hastalıklar veya
                                yaşa bağlı kardiyovasküler sorunlar gibi yaşa
                                bağlı rahatsızlıklardan muzdarip insanlar,
                                kapsamlı tedavi planlarının bir parçası olarak
                                MSC eksozom tedavilerinden yararlanabilirler.
                                Yaşlandıkça sağlıklarını koruma konusunda
                                bilinçli olan ve genel refahı ve uzun ömürlülüğü
                                teşvik etmek için yeni, en son tedavilerle
                                ilgilenen bireyler uygun adaylar olacaktır.
                                Kırışıklıkları azaltarak, kollajen sentezini
                                teşvik ederek ve cilt elastikiyetini artırarak
                                ciltlerinin görünümünü iyileştirmek isteyen
                                insanlar, cilt bakım rejimlerinin bir parçası
                                olarak MSC eksozom tedavileri ile
                                ilgilenebilirler. Düzenli fiziksel aktivite
                                yapan ve kas kütlesi, güç, dayanıklılık ve eklem
                                sağlığındaki yaşa bağlı düşüşlere karşı koymak
                                isteyen sporcular ve bireyler, MSC eksozom
                                tedavilerini faydalı bulabilir. Aşağıdaki kötü
                                alışkanlıklara sahip tüm insanlar kötü beslenme
                                sigara içmek alkol stres sızdıran bağırsak kötü
                                uyku yüksek şekerli diyet kirlilik hareketsizlik
                                kronik enfeksiyon tanısı olan kişiler Ekzozom
                                tedavisinden fayda sağlayacaklardır. Sağlıksız
                                yaşlanma sinyali olan tüm insanlar; eklem
                                ağrısı, kas spam ödem, su tutma yorgunluk, baş
                                ağrısı acele saç dökülmesi havasız öksürük aşırı
                                terleme sindirim sorunları dask daire göz
                                torbaları aşırı kilolu obezite hafıza bozukluğu
                                olan kişiler Ekzozom tedavisinden fayda
                                sağlayacak kişilerdir.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                          <Card className="mb-4">
                            <Card.Body>
                              <Card.Title>
                                Otizm için Eksozom (ExOtizm)
                              </Card.Title>
                              <Card.Text>
                                Otizm, ömür boyu süren ve bir dizi semptoma
                                sahip olan nörolojik bir durumdur. Sosyal
                                etkileşim sorunları, iletişim zorlukları ve
                                tekrarlayan davranış gruplarının ortaya çıkması
                                bozukluğun özelliklerinden bazılarıdır. Hala
                                belirsiz olan genetik ve çevresel faktörler
                                otizmin etiyolojisini zorlaştırıyor. Ancak son
                                on yılda otizm spektrum bozukluğunun (ASD)
                                görülme sıklığı önemli ölçüde artmıştır. Otizm
                                spektrum bozukluğunun yaygınlığındaki artış
                                nedeniyle yeni biyobelirteçlerin ve
                                farmakoterapilerin icadı gereklidir. Klinik
                                araştırmalarda mezenkimal kök hücre (MSC)
                                tedavisinin otizm spektrum bozukluğu
                                semptomlarını hafifletmede yararlı olduğu
                                gösterilmiştir.Bu anlamda kök hücre
                                tedavisindeki son gelişmeler otistik bireylere
                                umut vermektedir. Kök hücreler tarafından
                                salınan eksozomlar bu konuda aracılık
                                edebilirler. Vücudumuzdaki rejenerasyon
                                süreçlerini kontrol etme yetenekleri nedeniyle,
                                eksozomlar otoimmün bozuklukları, kronik
                                inflamasyon ve diğer kronik dejeneratif
                                durumları olan bireylerin tedavisinde yardımcı
                                olabilir. Terapötik başarının en azından bir
                                kısmı, eksozomların anti-inflamatuar
                                özelliklerinden kaynaklanmaktadır. Eksozomların
                                kan-beyin bariyerini (BBB) geçme ve nöral
                                dokudaki enflamatuar yolları inhibe ederken
                                nöronal farklılaşmayı ve gelişimi teşvik etme
                                yeteneği bunun nedeni olabilir. Eksozom
                                tedavisinin terapötik etkinliği, otizmle ilgili
                                semptomları hafifletmek için hücresiz bir tedavi
                                olarak otizm spektrum bozukluğu da dahil olmak
                                üzere nörolojik bozuklukları yönetmek için
                                potansiyel bir yöntem olmaktadır.. Mezenkimal
                                kök hücrelerin üretip serbest bıraktığı
                                eksozomlar, farklı hücre tipleri üzerinde çok
                                çeşitli etkilere sahiptir ve yaralı organlarda
                                endojen onarım yollarını aktive ederek
                                işlevlerini geri kazandırabilirler. Eksozom,
                                otizmden zarar gören belirli biyokimyasal
                                yolları eski haline getirmek için alıcı
                                hücrelere teslim edilebilir. Eksozomların
                                ayrıca, büyük olasılıkla anti-inflamatuar
                                sitokinlerin üretimini teşvik etme
                                yeteneklerinin bir sonucu olarak, nörolojik
                                bozukluklar üzerinde anti-inflamatuar etkileri
                                vardır. Otizm Spektrum Bozukluğu’nda görülen
                                yüksek pro-inflamatuar durumu azaltma amacıyla
                                Eksozom uygulanması bu açıdan da avantajlı
                                olabilir. <br />
                                <br />
                                <h4>
                                  Otizmde Kök Hücre Ekzozom Tedavisi ile
                                  Beklenen Sonuçlar
                                </h4>{" "}
                                Otizm için spesifik bir biyolojik belirteç
                                olmaması nedeniyle, tanı yaşamın ilk
                                yıllarındaki davranışların gözlemlenmesi ile
                                konur. Ancak otizm için ekzozom tedavisinin
                                kullanılması, bozukluğu açıklayan genetik ve
                                biyokimyasal değişikliklerle mümkün olmuştur.
                                Özellikle yakındaki hücreler üzerindeki etkileri
                                ve özellikle immünomodülatör aktiviteleri, otizm
                                tedavisinde faydalı uygulamaların önünü
                                açmıştır. Kök hücrelerin üretip serbest
                                bıraktığı ekzozomlar, farklı hücre tipleri
                                üzerinde çok çeşitli etkilere sahiptir ve yaralı
                                organlarda endojen onarım yollarını aktive
                                ederek işlevlerini geri kazandırabilirler.
                                Eksozom, otizmden zarar gören belirli
                                biyokimyasal yolları eski haline getirmek için
                                alıcı hücrelere teslim edilebilir. Ekzozomların
                                ayrıca, büyük olasılıkla anti-inflamatuar
                                sitokinlerin üretimini teşvik etme
                                yeteneklerinin bir sonucu olarak, nörolojik
                                bozukluklar üzerinde anti-inflamatuar etkileri
                                vardır. Otizm Spektrum Bozukluğu’nda görülen
                                yüksek pro-inflamatuar durumu azaltma amacıyla
                                Ekzozom uygulanması bu açıdan da avantajlı
                                olabilir. <br />
                                <br />
                                <h4>
                                  Otizm için Eksozom Tedavisinin Beklenen
                                  Sonuçları Nelerdir?
                                </h4>
                                Çocukların göz temaslarını sürdürme olasılıkları
                                daha yüksektir. Özellikle yönlere, kurallara ve
                                ebeveynlerden gelen komutlara dikkatinin artışı
                                ve uyması artar. Hiperaktivite ve takıntılar
                                azalır, Tekrarlayan davranışlar azalır. Evde
                                veya dış ortamlarda daha uyumlu davranış artar.
                                Sözlü iletişim artar Öğrenme kapasitesi
                                gelişirken konsantrasyon ve dikkat süresi artar.
                                Yazma becerileri gelişir. Kök hücreler
                                tarafından oluşturulan eksozomlar, beynin
                                hedeflenen belirli bölgelerine gidebilir. Bu
                                özellik potansiyel otizm tedavisi için önemlidir
                                çünkü otistik çocuklar beyin kan bariyeri
                                bütünlüğü azalmıştır.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>Ekzozom Tedavisi Talep Edin</Card.Title>
                          <Card.Text>
                            Rejeneratif tıbbın yeni sınırı olan EXOSOME THERAPY
                            hakkında şimdi bir konsültasyon talep edin.
                            Koordinatörümüz tarafından iletişime geçmek için
                            info@drbengusuozkan.com adresine e-posta gönderin
                            veya aşağıdaki formu doldurun.
                          </Card.Text>
                          <ExozomForm />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Footeralt />
      </div>
    </div>
  );
}

export default RejaneratifTıp;
