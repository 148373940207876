import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import './Navbar.css';
import functional from "../images/functionak.png";
import regeneratif from "../images/regeneratif.png";
import womanandchild from "../images/womanandchild.png";
import idCheckUp from "../images/checkup.png";
import skin from "../images/skin.png";
import math from "../images/hearthmath.png";
import pre from "../images/preservice.png";
import serum from "../images/serum.png";
import pdfFile from "../../WEB 2024 HASTA BAŞVURU FORMU.pdf";

const CustomNavbar = (props) => {
    const [showModal, setShowModal] = useState(false);

    const handleAppointment = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const phoneNumber = "+905434017092"; 

    const openWhatsApp = () => {
        const message = "Merhaba, randevu almak istiyorum."; 
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    const downloadPDF = () => {
        const link = document.createElement('a');
        link.href = pdfFile;
        link.setAttribute('download', 'randevu_formu.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        openWhatsApp();
    };

    const ServiceMenuItem = ({ to, title, imageUrl }) => {
        return (
            <NavDropdown.Item as={NavLink} to={to} className="service-menu-item">
                <img src={imageUrl} alt={title} className="service-image" />
                <span className="service-title">{title}</span>
            </NavDropdown.Item>
        );
    };

    return (
        <div className={props.className}>
            <Navbar bg="white" expand="lg" variant="light" className="custom-navbar">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav style={{paddingLeft: '50px'}}  className="ml-auto">
                        <NavLink to="/" exact>ANA SAYFA</NavLink>
                        <NavDropdown title="HİZMETLER" id="basic-nav-dropdown">
                            <div style={{width: '380px'}} className="service-menu">
                                <ServiceMenuItem to='/fonksiyonelTip' title="Fonksiyonel Tıp" imageUrl={functional} />
                                <ServiceMenuItem to='/rejaneratif' title="Rejeneratif Tıp" imageUrl={regeneratif} />
                                <ServiceMenuItem to='/intravenoz' title="IV Terapiler" imageUrl={serum} />
                                <ServiceMenuItem to='/idCheckUp' title="ID Check UP" imageUrl={idCheckUp} />
                                <ServiceMenuItem to='/kadinvecocuk' title="Kadın & Çocuk Sağlığı" imageUrl={womanandchild} />
                                <ServiceMenuItem to='/butunselGuzellik' title="Bütünsel Güzellik" imageUrl={skin} />
                                <ServiceMenuItem to='/heartMath' title="Kalp Matematiği ile Stres Yönetimi" imageUrl={math} />
                                <ServiceMenuItem to='/prekonsepsiyonel' title="Prekonsepsiyonel Sağlık Bakımı" imageUrl={pre} />
                            </div>
                        </NavDropdown>
                        <NavLink to="/blog">BLOG</NavLink>
                        <NavLink to="/sss">SSS</NavLink>
                    </Nav>
                </Navbar.Collapse>
                <div className="social-icons">
                    <Button onClick={handleAppointment} className="btn-randevuOl">Randevu Al</Button>
                    <SocialIcon href="https://www.facebook.com/bengusu.ozkan">
                        <FaFacebook size={25} color='grey' />
                    </SocialIcon>
                    <SocialIcon href="https://www.instagram.com/drbengusu.ozkan/">
                        <FaInstagram size={25} color='grey' />
                    </SocialIcon>
                    <SocialIcon href="https://www.youtube.com/@metabolicbalanceturkcumhur7745">
                        <FaYoutube size={30} color='grey' />
                    </SocialIcon>
                </div>
            </Navbar>

            <Modal style={{ maxWidth: '70%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Randevu Bilgileri</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Randevu bilgileriyle ekteki formu doldurup iletiniz.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={downloadPDF}>
                        Formu İndir ve WhatsApp'a Git
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const NavLink = ({ to, children }) => {
    const { pathname } = useLocation();

    return (
        <Nav.Link href={to} className={pathname === to ? 'active' : ''}>
            {children}
        </Nav.Link>
    );
};

const SocialIcon = ({ href, children }) => {
    return (
        <Nav.Link href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </Nav.Link>
    );
};

export default CustomNavbar;
